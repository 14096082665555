import { Dispatch, SetStateAction} from "react";
import { OverlayMain } from "../../assets/main";

interface Props {
  hidden: boolean;
  hide: Dispatch<SetStateAction<boolean>>;
  enter: () => void;
}

const Background: React.FC<Props> = ({ hidden, enter }) => {
  

  return (
    <div className={`fixed z-40 w-full delay-700 duration-500 bg-[#19b9d1] ${hidden ? "h-0" : "h-full min-h-screen"}`}>
      <img useMap="#entrance" src={OverlayMain} alt="" className={`absolute z-[60] top-0 left-0 duration-500 ${hidden ? "opacity-0" : "opacity-100"}`} />
      <map name="entrance">
        <area href="mailto:wahyusyawal69@gmail.com" alt="wahyu-mail" title="wahyu-mail" coords="335,592,660,629" shape="rect" />
        <area href="mailto:ahmadby100@gmail.com" alt="ahmad-mail" title="ahmad-mail" coords="698,590,988,629" shape="rect" />
        <area target="_blank" href="https://www.linkedin.com/in/wahyu-syawal-4a35b4221/" alt="wahyu-linkedin" title="wahyu-linkedin" coords="481,644,657,676" shape="rect" />
        <area target="_blank" href="https://www.instagram.com/skummykiddo/" alt="wahyu-instagram" title="wahyu-instagram" coords="532,693,660,732" shape="rect" />
        <area target="_blank" href="https://twitter.com/skummykiddo" alt="wahyu-twitter" title="wahyu-twitter" coords="569,750,659,787" shape="rect" />
        <area target="_blank" href="https://github.com/ahmadby100" alt="ahmad-github" title="ahmad-github" coords="698,640,856,679" shape="rect" />
        <area onClick={enter} alt="enter" title="enter" coords="1166,665,1534,752" shape="rect" className="cursor-pointer" />
        
        {/* <area alt="wahyu" coords="372,540,586,571" shape="rect" onMouseEnter={hoverW} />
        <area alt="ahmad" coords="564,631,751,660" shape="rect" onMouseEnter={hoverA} />
        <area alt="click me to enter" className="cursor-pointer" onClick={enter} coords="1019,664,1388,754" shape="rect" />
        <area href={`mailto:${person === "a" ? "ahmadby100@gmail.com" : "wahyusyawal69@gmail.com"}`} className={person ? "cursor-pointer" : "pointer-events-none"} alt="ahmad-email" coords="382,696,808,752" shape="rect" />
        <area href={person === "a" ? "https://github.com/ahmadby100" : "https://www.linkedin.com/in/wahyu-syawal-4a35b4221/"} target="_blank" className={person ? "cursor-pointer" : "pointer-events-none"} alt="ahmad-email" coords="382,768,618,820" shape="rect" /> */}
      </map>
      <map name="ahmad-map">
      </map>
    </div>
  )
}

export default Background;