import { Dispatch, SetStateAction } from "react";
import { y2s5c1_files } from "../../assets/ow_cd_y2c1";
import { y2s5c2_files } from "../../assets/ow_cd_y2c2";
import { y2s5c3_files } from "../../assets/ow_cd_y2c3";
import{ y2s5fanart_files } from "../../assets/ow_cd_y2sb";
import { char_header_1, char_header_2, char_header_3, char_header_fanart } from "../../assets/ow_headers";
import Divider from "../Parts/Divider";
import File from "../Parts/File";
import { Item } from "../Specialization";
import { FolderItem } from "../Winbox";

interface FolderProps {
  setFolderItem: Dispatch<SetStateAction<FolderItem | undefined>>;
  hover: Dispatch<SetStateAction<string>>;
  setItem?: Dispatch<SetStateAction<Item | undefined>>;
  changeDir?: (name: string, path: string) => void;
  back?: (item: { path: string; name: string }, set: boolean) => void;
  view?: string;
  setView?: Dispatch<SetStateAction<string>>;
}

const CharacterDesign: React.FC<FolderProps> = ({ setFolderItem, hover, setItem, changeDir, view, setView }) => {
  // const [view, setView] = useState<string>("cd");
  return (
    <>
      <div className="flex flex-col mb-5">
        {view === "cd" && (
          <>
            <img onClick={() => changeDir ? changeDir("Year_2_Sembreak_Fanart", "Year_2_Sembreak_Fanart") : null} src={char_header_fanart} alt="character-fanart" className="img-headers" />
            <img onClick={() => changeDir ? changeDir("Year_2_Sem5_Char1", "Year_2_Sem5_Char1") : null} src={char_header_1} alt="character-1" className="img-headers" />
            <img onClick={() => changeDir ? changeDir("Year_2_Sem5_Char2", "Year_2_Sem5_Char2") : null} src={char_header_2} alt="character-2" className="img-headers" />
            <img onClick={() => changeDir ? changeDir("Year_2_Sem5_Char3", "Year_2_Sem5_Char3") : null} src={char_header_3} alt="character-3" className="img-headers" />
            {/* <Folder changeDir={changeDir} openFolder={setView} hover={hover} folderItem={y2s5fanart} title="Year_2_Sembreak_Fanart"   />
            <Folder changeDir={changeDir} openFolder={setView} hover={hover} folderItem={y2s5c1} title="Year_2_Sem5_Char1"  />
            <Folder changeDir={changeDir} openFolder={setView} hover={hover} folderItem={y2s5c2} title="Year_2_Sem5_Char2"  />
            <Folder changeDir={changeDir} openFolder={setView} hover={hover} folderItem={y2s5c3} title="Year_2_Sem5_Char3"  /> */}
          </>)}
      </div>
      {view === "Year_2_Sembreak_Fanart" && <Fanart setFolderItem={setFolderItem} hover={hover} setItem={setItem} />}
      {view === "Year_2_Sem5_Char1" && <CharacterOne setFolderItem={setFolderItem} hover={hover} setItem={setItem} />}
      {view === "Year_2_Sem5_Char2" && <CharacterTwo setFolderItem={setFolderItem} hover={hover} setItem={setItem} />}
      {view === "Year_2_Sem5_Char3" && <CharacterThree setFolderItem={setFolderItem} hover={hover} setItem={setItem} />}
    </>
  )
}

interface FanartType {
  pglang: Array<FileProps>,
  rocky: Array<FileProps>,
  sheck: Array<FileProps>,
  tyler: Array<FileProps>,
  uncloked: Array<FileProps>,
}

const Fanart: React.FC<FolderProps> = ({ hover, setItem }) => {
  const folders = y2s5fanart_files as FanartType;
  return (
  <div className="grid grid-cols-3 gap-5">
    <Divider>Fanart: pgLang</Divider>
    {folders.pglang.map((item, index) => <File hover={hover} item={item} title={item.title} key={index} setItem={setItem} />)}
    <Divider>Fanart: A$AP Rocky</Divider>
    {folders.rocky.map((item, index) => <File hover={hover} item={item} title={item.title} key={index} setItem={setItem} />)}
    <Divider>Fanart: Sheck Wes</Divider>
    {folders.sheck.map((item, index) => <File hover={hover} item={item} title={item.title} key={index} setItem={setItem} />)}
    <Divider>Fanart: Tyler the Creator</Divider>
    {folders.tyler.map((item, index) => <File hover={hover} item={item} title={item.title} key={index} setItem={setItem} />)}
    <Divider>Fanart: Uncloked</Divider>
    {folders.uncloked.map((item, index) => <File hover={hover} item={item} title={item.title} key={index} setItem={setItem} />)}
  </div>
)};

const CharacterOne: React.FC<FolderProps> = ({ hover, setItem }) => (
  <div className="grid grid-cols-3 gap-5">
    {y2s5c1_files.map((item, index) => (<File hover={hover} item={item} title={item.title} key={index} setItem={setItem} />))}
  </div>
);

const CharacterTwo: React.FC<FolderProps> = ({ hover, setItem }) => (
  <div className="grid grid-cols-3 gap-5">
    {y2s5c2_files.map((item, index) => (<File hover={hover} item={item} title={item.title} key={index} setItem={setItem} />))}
  </div>
);

const CharacterThree: React.FC<FolderProps> = ({ hover, setItem }) => (
  <div className="grid grid-cols-3 gap-5">
    {y2s5c3_files.map((item, index) => (<File hover={hover} item={item} title={item.title} key={index} setItem={setItem} />))}
  </div>
);

export default CharacterDesign;