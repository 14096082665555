const skatedeck_vid1 = "/assets/otherworks/just for fun/SkateDeckDesigns/1.mp4";
const skatedeck_vid2 = "/assets/otherworks/just for fun/SkateDeckDesigns/2.mp4";
const skatedeck_img1 = "/assets/otherworks/just for fun/SkateDeckDesigns/ig1.jpg";
const skatedeck_img2 = "/assets/otherworks/just for fun/SkateDeckDesigns/ig2.jpg";
const skatedeck_img3 = "/assets/otherworks/just for fun/SkateDeckDesigns/ig3.jpg";
const skatedeck_img4 = "/assets/otherworks/just for fun/SkateDeckDesigns/ig4.jpg";
const skatedeck_img5 = "/assets/otherworks/just for fun/SkateDeckDesigns/ig5.jpg";
const skatedeck_img6 = "/assets/otherworks/just for fun/SkateDeckDesigns/ig6.jpg";

const storyPitch_final_1_slide1 = "/assets/otherworks/just for fun/Storypitches_FinalProject/1/Slide1.JPG";
const storyPitch_final_1_slide2 = "/assets/otherworks/just for fun/Storypitches_FinalProject/1/Slide2.JPG";
const storyPitch_final_1_slide3 = "/assets/otherworks/just for fun/Storypitches_FinalProject/1/Slide3.JPG";
const storyPitch_final_1_slide4 = "/assets/otherworks/just for fun/Storypitches_FinalProject/1/Slide4.JPG";
const storyPitch_final_1_slide5 = "/assets/otherworks/just for fun/Storypitches_FinalProject/1/Slide5.JPG";
const storyPitch_final_1_slide6 = "/assets/otherworks/just for fun/Storypitches_FinalProject/1/Slide6.JPG";
const storyPitch_final_1_slide7 = "/assets/otherworks/just for fun/Storypitches_FinalProject/1/Slide7.JPG";

const storyPitch_final_2_slide1 = "/assets/otherworks/just for fun/Storypitches_FinalProject/2/Slide8.JPG";
const storyPitch_final_2_slide2 = "/assets/otherworks/just for fun/Storypitches_FinalProject/2/Slide9.JPG";
const storyPitch_final_2_slide3 = "/assets/otherworks/just for fun/Storypitches_FinalProject/2/Slide10.JPG";
const storyPitch_final_2_slide4 = "/assets/otherworks/just for fun/Storypitches_FinalProject/2/Slide11.JPG";
const storyPitch_final_2_slide5 = "/assets/otherworks/just for fun/Storypitches_FinalProject/2/Slide12.JPG";
const storyPitch_final_2_slide6 = "/assets/otherworks/just for fun/Storypitches_FinalProject/2/Slide13.JPG";
const storyPitch_final_2_slide7 = "/assets/otherworks/just for fun/Storypitches_FinalProject/2/Slide14.JPG";

const storyPitch_final_3_slide1 = "/assets/otherworks/just for fun/Storypitches_FinalProject/3/Slide15.JPG";
const storyPitch_final_3_slide2 = "/assets/otherworks/just for fun/Storypitches_FinalProject/3/Slide16.JPG";
const storyPitch_final_3_slide3 = "/assets/otherworks/just for fun/Storypitches_FinalProject/3/Slide17.JPG";
const storyPitch_final_3_slide4 = "/assets/otherworks/just for fun/Storypitches_FinalProject/3/Slide18.JPG";
const storyPitch_final_3_slide5 = "/assets/otherworks/just for fun/Storypitches_FinalProject/3/Slide19.JPG";
const storyPitch_final_3_slide6 = "/assets/otherworks/just for fun/Storypitches_FinalProject/3/Slide20.JPG";
const storyPitch_final_3_slide7 = "/assets/otherworks/just for fun/Storypitches_FinalProject/3/Slide21.JPG";

const tradPaints_1_cover = "/assets/otherworks/just for fun/TraditionalPaintings/1 (cover).jpg";
const tradPaints_1_2 = "/assets/otherworks/just for fun/TraditionalPaintings/2.jpg";
const tradPaints_1_3 = "/assets/otherworks/just for fun/TraditionalPaintings/3.jpg";
const tradPaints_2_cover = "/assets/otherworks/just for fun/TraditionalPaintings/4 (cover).jpg";
const tradPaints_2_2 = "/assets/otherworks/just for fun/TraditionalPaintings/5.jpg";
const tradPaints_2_3 = "/assets/otherworks/just for fun/TraditionalPaintings/6.jpg";
const tradPaints_3_cover = "/assets/otherworks/just for fun/TraditionalPaintings/7 (cover).jpg";
const tradPaints_3_2 = "/assets/otherworks/just for fun/TraditionalPaintings/8.jpg";
const tradPaints_3_3 = "/assets/otherworks/just for fun/TraditionalPaintings/9.jpg";

const edits_thumb = "/assets/otherworks/just for fun/VideoEdits/thumb.png";
const edits_1 = "/assets/otherworks/just for fun/VideoEdits/1.mp4";
const edits_2 = "/assets/otherworks/just for fun/VideoEdits/2.mp4";
const edits_3 = "/assets/otherworks/just for fun/VideoEdits/3.mp4";
const edits_4 = "/assets/otherworks/just for fun/VideoEdits/4.mp4";
const edits_5 = "/assets/otherworks/just for fun/VideoEdits/5.mp4";
const edits_6 = "/assets/otherworks/just for fun/VideoEdits/6.mp4";


const skatedeck = {
  title: "Skate Deck Designs",
  images: [
    skatedeck_img1, skatedeck_img2, skatedeck_img3, skatedeck_img4, skatedeck_img5, skatedeck_img6,
  ],
  videos: [
    skatedeck_vid1, skatedeck_vid2,
  ],
  files: 8,
  description: `Just like my VIVID button up designs, i wanted to change the canvas to skate decks.
  To my surprise, even blank decks are pretty pricey but my budget had enough for only two decks.
  But i was happy with the final outcome and sold it for a reasonable price.
  Obviously, no body buyed it until now but it's cool. i decided to just hang it up the wall at my friends place.`
};

const storyPitches = {
  title: "Storypitches for Final Project",
  images: [
    storyPitch_final_1_slide1, storyPitch_final_1_slide2, storyPitch_final_1_slide3, storyPitch_final_1_slide4, storyPitch_final_1_slide5, storyPitch_final_1_slide6, storyPitch_final_1_slide7,
    storyPitch_final_2_slide1, storyPitch_final_2_slide2, storyPitch_final_2_slide3, storyPitch_final_2_slide4, storyPitch_final_2_slide5, storyPitch_final_2_slide6, storyPitch_final_2_slide7,
    storyPitch_final_3_slide1, storyPitch_final_3_slide2, storyPitch_final_3_slide3, storyPitch_final_3_slide4, storyPitch_final_3_slide5, storyPitch_final_3_slide6, storyPitch_final_3_slide7,
  ],
  files: 21,
  description: `When reaching my final project in my final year, every student, from the DigitalAnimation and Illustration Batch had to pitch at least 3 stories to be approved.
  We each had a month or so and as mentioned before in my 2Danimation folder, i absolutely suck at making up stories on the fly.
  Surprisingly enough, i managed to think of 3 in the time given and spent a ton of effort illustrating my pitches.
  I was really happy with the outcome, but on the day of the presentation all my stories got rejected lol. `
};

const traditionalPaintings = {
  title: "Traditional Paintings",
  images: [
    tradPaints_1_cover, tradPaints_1_2, tradPaints_1_3,
    tradPaints_2_cover, tradPaints_2_2, tradPaints_2_3,
    tradPaints_3_cover, tradPaints_3_2, tradPaints_3_3,
  ],
  files: 9,
  description: `Nothing much to say about this really.
  My friends got a new place and it just got a renovation so it was pretty empty.
  I offered some free paintings to fill up the blank walls and sure enough they were pretty happy with the look.
  I even hanged up some VIVID canvas paintings as well.
  Painting is definetely one of the best art medium for me and i often use to vent since there really is no expectations of me making "remarkable paintings".`
};


const skatedeck_files = {
  vids: [
    {
      id: "skatedeck-1",
      title: "1.mp4",
      date: "2021-04-01", 
      description: skatedeck.description, 
      size: "67.11 MB", 
      src: skatedeck.videos[0], 
      thumb: skatedeck.images[0], 
    },
    {
      id: "skatedeck-2",
      title: "2.mp4",
      date: "2021-04-02", 
      description: skatedeck.description, 
      size: "49.43 MB", 
      src: skatedeck.videos[1], 
      thumb: skatedeck.images[1], 
    }
  ],
  imgs: [
    {
      id: "skatedeck-3",
      title: "ig1.jpg",
      date: "2021-04-03",
      description: skatedeck.description,
      size: "255 KB",
      src: null,
      thumb: skatedeck.images[0],
    },
    {
      id: "skatedeck-4",
      title: "ig2.jpg",
      date: "2021-04-03",
      description: skatedeck.description,
      size: "251 KB",
      src: null,
      thumb: skatedeck.images[1],
    },
    {
      id: "skatedeck-5",
      title: "ig3.jpg",
      date: "2021-04-03",
      description: skatedeck.description,
      size: "313 KB",
      src: null,
      thumb: skatedeck.images[2],
    },
    {
      id: "skatedeck-6",
      title: "ig4.jpg",
      date: "2021-04-03",
      description: skatedeck.description,
      size: "270 KB",
      src: null,
      thumb: skatedeck.images[3],
    },
    {
      id: "skatedeck-7",
      title: "ig5.jpg",
      date: "2021-04-03",
      description: skatedeck.description,
      size: "235 KB",
      src: null,
      thumb: skatedeck.images[4],
    },
    {
      id: "skatedeck-8",
      title: "ig6.jpg",
      date: "2021-04-03",
      description: skatedeck.description,
      size: "246 KB",
      src: null,
      thumb: skatedeck.images[5],
    }
  ],
}

const storyPitch_files = {
  pitch1: [{
      id: "storyPitch_final-1_slide1",
      title: "1.jpg",
      date: "2021-04-01",
      description: storyPitches.description,
      size: "68.90 KB",
      src: null,
      thumb: storyPitch_final_1_slide1,
    },
    {
      id: "storyPitch_final-1_slide2",
      title: "2.jpg",
      date: "2021-04-02",
      description: storyPitches.description,
      size: "69.50 KB",
      src: null,
      thumb: storyPitch_final_1_slide2,
    },
    {
      id: "storyPitch_final-1_slide3",
      title: "3.jpg",
      date: "2021-04-03",
      description: storyPitches.description,
      size: "95.3 KB",
      src: null,
      thumb: storyPitch_final_1_slide3,
    },
    {
      id: "storyPitch_final-1_slide4",
      title: "4.jpg",
      date: "2021-04-04",
      description: storyPitches.description,
      size: "97.1 KB",
      src: null,
      thumb: storyPitch_final_1_slide4,
    },
    {
      id: "storyPitch_final-1_slide5",
      title: "5.jpg",
      date: "2021-04-05",
      description: storyPitches.description,
      size: "97.1 KB",
      src: null,
      thumb: storyPitch_final_1_slide5,
    },
    {
      id: "storyPitch_final-1_slide6",
      title: "6.jpg",
      date: "2021-04-06",
      description: storyPitches.description,
      size: "97.1 KB",
      src: null,
      thumb: storyPitch_final_1_slide6,
    },
    {
      id: "storyPitch_final-1_slide7",
      title: "7.jpg",
      date: "2021-04-07",
      description: storyPitches.description,
      size: "97.1 KB",
      src: null,
      thumb: storyPitch_final_1_slide7,
  }],
  pitch2: [{
    id: "storyPitch_final-2_slide1",
    title: "1.jpg",
    date: "2021-04-01",
    description: storyPitches.description,
    size: "68.90 KB",
    src: null,
    thumb: storyPitch_final_2_slide1,
  },
  {
    id: "storyPitch_final-2_slide2",
    title: "2.jpg",
    date: "2021-04-02",
    description: storyPitches.description,
    size: "69.50 KB",
    src: null,
    thumb: storyPitch_final_2_slide2,
  },
  {
    id: "storyPitch_final-2_slide3",
    title: "3.jpg",
    date: "2021-04-03",
    description: storyPitches.description,
    size: "95.3 KB",
    src: null,
    thumb: storyPitch_final_2_slide3,
  },
  {
    id: "storyPitch_final-2_slide4",
    title: "4.jpg",
    date: "2021-04-04",
    description: storyPitches.description,
    size: "97.1 KB",
    src: null,
    thumb: storyPitch_final_2_slide4,
  },
  {
    id: "storyPitch_final-2_slide5",
    title: "5.jpg",
    date: "2021-04-05",
    description: storyPitches.description,
    size: "97.1 KB",
    src: null,
    thumb: storyPitch_final_2_slide5,
  },
  {
    id: "storyPitch_final-2_slide6",
    title: "6.jpg",
    date: "2021-04-06",
    description: storyPitches.description,
    size: "97.1 KB",
    src: null,
    thumb: storyPitch_final_2_slide6,
  },
  {
    id: "storyPitch_final-2_slide7",
    title: "7.jpg",
    date: "2021-04-07",
    description: storyPitches.description,
    size: "97.1 KB",
    src: null,
    thumb: storyPitch_final_2_slide7,
  }],
  pitch3: [{
    id: "storyPitch_final-3_slide1",
    title: "1.jpg",
    date: "2021-04-01",
    description: storyPitches.description,
    size: "68.90 KB",
    src: null,
    thumb: storyPitch_final_3_slide1,
  },
  {
    id: "storyPitch_final-3_slide2",
    title: "2.jpg",
    date: "2021-04-02",
    description: storyPitches.description,
    size: "69.50 KB",
    src: null,
    thumb: storyPitch_final_3_slide2,
  },
  {
    id: "storyPitch_final-3_slide3",
    title: "3.jpg",
    date: "2021-04-03",
    description: storyPitches.description,
    size: "95.3 KB",
    src: null,
    thumb: storyPitch_final_3_slide3,
  },
  {
    id: "storyPitch_final-3_slide4",
    title: "4.jpg",
    date: "2021-04-04",
    description: storyPitches.description,
    size: "97.1 KB",
    src: null,
    thumb: storyPitch_final_3_slide4,
  },
  {
    id: "storyPitch_final-3_slide5",
    title: "5.jpg",
    date: "2021-04-05",
    description: storyPitches.description,
    size: "97.1 KB",
    src: null,
    thumb: storyPitch_final_3_slide5,
  },
  {
    id: "storyPitch_final-3_slide6",
    title: "6.jpg",
    date: "2021-04-06",
    description: storyPitches.description,
    size: "97.1 KB",
    src: null,
    thumb: storyPitch_final_3_slide6,
  },
  {
    id: "storyPitch_final-3_slide7",
    title: "7.jpg",
    date: "2021-04-07",
    description: storyPitches.description,
    size: "97.1 KB",
    src: null,
    thumb: storyPitch_final_3_slide7,
  }],
};

const traditionalPaintings_files = [
  {
    id: "tradPaints-1_cover",
    title: "1.jpg",
    date: "2021-04-01",
    description: traditionalPaintings.description,
    size: "68.90 KB",
    src: null,
    thumb: tradPaints_1_cover,
  },
  {
    id: "tradPaints-1_2",
    title: "2.jpg",
    date: "2021-04-02",
    description: traditionalPaintings.description,
    size: "69.50 KB",
    src: null,
    thumb: tradPaints_1_2,
  },
  {
    id: "tradPaints-1_3",
    title: "3.jpg",
    date: "2021-04-03",
    description: traditionalPaintings.description,
    size: "95.3 KB",
    src: null,
    thumb: tradPaints_1_3,
  },
  {
    id: "tradPaints-2_cover",
    title: "4.jpg",
    date: "2021-04-04",
    description: traditionalPaintings.description,
    size: "97.1 KB",
    src: null,
    thumb: tradPaints_2_cover,
  },
  {
    id: "tradPaints-2_2",
    title: "5.jpg",
    date: "2021-04-05",
    description: traditionalPaintings.description,
    size: "97.1 KB",
    src: null,
    thumb: tradPaints_2_2,
  },
  {
    id: "tradPaints-2_3",
    title: "6.jpg",
    date: "2021-04-06",
    description: traditionalPaintings.description,
    size: "97.1 KB",
    src: null,
    thumb: tradPaints_2_3,
  },
  {
    id: "tradPaints-3_cover",
    title: "7.jpg",
    date: "2021-04-07",
    description: traditionalPaintings.description,
    size: "97.1 KB",
    src: null,
    thumb: tradPaints_3_cover,
  },
  {
    id: "tradPaints-3_2",
    title: "8.jpg",
    date: "2021-04-08",
    description: traditionalPaintings.description,
    size: "97.1 KB",
    src: null,
    thumb: tradPaints_3_2,
  },
  {
    id: "tradPaints-3_3",
    title: "9.jpg",
    date: "2021-04-09",
    description: traditionalPaintings.description,
    size: "97.1 KB",
    src: null,
    thumb: tradPaints_3_3,
  }
]

const videoEdits = {
  title: "Video Edits",
  images: [
    edits_thumb,
  ],
  videos: [
    edits_1, edits_2, edits_3, edits_4, edits_5, edits_6,
  ],
  files: 6,
  description: `During highschool, i encountered a video editing software called Sony Vegas Pro.
  A lot of my influence came from Instagram editors like InspiredIsland and 6beanerish and i mostly just tried to mimick their style.
  Obviously i could never reach their level but it was fun doing it since there was no expectations of me making good videos.
  Looking back at it, i was really in flow state where i just contantly make videos after videos. 
  The ones shown here are the best ones amongst almost a hundred videos in archive.`,
};

const just4Fun = {
  title: "Just for Fun",
  images: [
    tradPaints_1_cover
  ],
  files: 44,
  description: `Just for fun placeholder text`
}

export default just4Fun;

export {
  skatedeck,
  storyPitches,
  traditionalPaintings,
  skatedeck_files,
  storyPitch_files,
  traditionalPaintings_files,
  videoEdits,
}