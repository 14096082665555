import { Dispatch, SetStateAction, useState } from "react";
import OtherWorks from "../OtherWorks";

// import WinboxReact from "winbox-react";

import Specialization from "../Specialization";
import "./index.css";

export interface FolderItem {
  title: string;
  images: string[];
  videos?: string[];
  files: number;
  description: string;
}

interface Props {
  children?: React.ReactNode;
  close: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  type: "spec" | "othw";
}

const Winbox: React.FC<Props> = ({ children, close, open, type }) => {
  const winbox = document.querySelectorAll('*[id^="winbox-"]');
  const [view, setView] = useState("home");
  const [full, setFull] = useState(false);

  const toggleFull = () => setFull(!full);


  // ${full ? "scale-100 rounded-none" : "scale-[0.86] rounded-t-xl rounded-b-md"}
  return winbox.length < 1 ?
    <div className={`z-50 modal w-full h-full duration-500 backdrop-blur ${open ? "modal-open" : ""}`}>
      <div className={`bg-[#131820] modal-box rounded-md max-h-full duration-500 p-0 m-0 overflow-y-hidden ${full ? "max-w-full xl:max-h-full" : "max-w-screen-xl xl:max-h-[calc(100vh-5em)]"}`}>
        {type === "spec"
          ? <Specialization close={close} setFull={toggleFull} setView={setView} view={view} />
          : <OtherWorks full={full} close={close} setFull={toggleFull} />
        }
      </div>
    </div>
  : <span></span>

}
export default Winbox;