import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Image } from "../Background";
import Titlebar from "../Parts/Titlebar";

interface Props {
  setPlayer: Dispatch<SetStateAction<boolean>>;
  title: string;
  src: string;
  type: string;
  imgs?: Image[];
}

const Player: React.FC<Props> = ({ setPlayer, title, src, type, imgs }) => {
  const [toggle, setToggle] = useState<boolean>(false);
  const [reTitle, updateTitle] = useState(title);
  
  useEffect(() => {
    setTimeout(() => setToggle(true), 200);
  },[]);
  
  const [full, maximize] = useState<boolean>(false);
  const setFull = () => maximize(!full);
  const carousel = useRef<HTMLDivElement>(null);
  
  function navigate(ti: string, next: string) {
    updateTitle(ti)
    const nextel = document.querySelector(next)!;
    nextel.scrollIntoView({behavior: "auto"})
  }
  

  return (
    <>
      <div className={`modal ${toggle ? "modal-open" : ""}`}>
        <div className={`h-fit ${full ? "max-w-full" : "max-w-screen-xl"} aspect-video rounded-t-sm border-2 border-t-0 ${type === "vid" ? "border-amber-600" : "border-sky-800"} rounded-b-md modal-box duration-100 p-0 pt-8`}>
          <Titlebar close={setPlayer} setFull={setFull} view="home" title={reTitle} maximize={true} className={type === "vid" ? "bg-amber-600" : "bg-sky-800"}  />
            {type === "vid" 
              ? <video src={src} className="w-full h-full bg-black" autoPlay controls></video> 
              : <div className="w-full h-full bg-black carousel" ref={carousel}>
                {imgs && imgs.map((img, i) => (
                  <div id={`slide${i + 1}`} key={i} className="relative object-contain w-full carousel-item">
                    <img src={img.thumb} className="object-contain max-w-full max-h-full mx-auto" alt="t" />
                    {imgs.length > 1 &&
                      <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                        <div onClick={() => navigate(img.title, `#slide${i + 1 === 1 ? imgs.length : i}`)} className="btn btn-circle btn-ghost">❮</div> 
                        <div onClick={() => navigate(img.title, `#slide${i + 1 === imgs.length ? 1 : i + 2}`)} className="btn btn-circle btn-ghost">❯</div>
                      </div>
                    }
                  </div> 
                ))}
                </div>
              }
        </div>
      </div>
    </>
  )
}


export default Player;
