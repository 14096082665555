import { createContext, useEffect, useRef, useState } from "react";
import { Background as bgSrc } from "../../assets/main";
import Overlay from "../Overlay";

import Navigation from "../Navigation";
import Player from "../Player";

export interface Image {
  thumb: string;
  title: string;
}

interface Props {
  className?: string;
}

export const playerContext = createContext<(newtitle: string, type: string, newsrc?: string, imgs?: Image[]) => void>(() => null);

const music = [
  "1. false prophets.mp3",
  "2. infinity 888.mp3",
  "3. killuminati.mp3",
  "4. 327.mp3",
  "5. books of war.mp3",
  "6. curls.mp3",
  "7. bweyy.mp3"
]

const Background: React.FC<Props> = () => {
  
  const [hidden, hide] = useState<boolean>(false);
  const [overlay, setOverlay] = useState<boolean>(true);
  const [player, setPlayer] = useState<boolean>(false);
  const [src, setSrc] = useState<string>("");
  const [imgd, setImgs] = useState<Image[]>([]);
  const [title, setTitle] = useState<string>("");
  const [type, setType] = useState<string>("");
  
  const [sound, playing] = useState<boolean>(false);
  const audioPlayer = useRef<HTMLAudioElement>(null);

  const enter = () => {
    hide(true);
    const randSrc = music[Math.floor(Math.random() * music.length)];
    console.log("Playing:", randSrc);
    audioPlayer.current!.muted = false;
    audioPlayer.current!.volume = 0.4;
    audioPlayer.current!.src = `/assets/music/${randSrc}`;
    audioPlayer.current!.play();
    setTimeout(() => setOverlay(false), 1200);
  }

  const toggleMute = (mute?: boolean) => {
    audioPlayer.current!.muted = mute ? mute : !audioPlayer.current!.muted
    playing(mute ? mute : audioPlayer.current!.muted);
  };

  const shuffle = () => {
    const randSrc = music[Math.floor(Math.random() * music.length)];
    audioPlayer.current!.src = `/assets/music/${randSrc}`;
    console.log("Playing:", randSrc);
    audioPlayer.current!.play();
  }

  const initPlayer = async (newtitle: string, newtype: string, newsrc?: string, imgs?: Image[] | Image) => {
    setTitle(newtitle)
    setType(newtype);
    if (newtype === "img" && imgs) {
      if (Array.isArray(imgs)) {
        await setImgs(imgs) 
      }
      else {
        await setImgs([imgs]);      
      }
    } else if (newtype === "vid" && newsrc) {
      setSrc(newsrc);
    }
    setPlayer(true);
  }
  const d = audioPlayer.current? audioPlayer.current.muted: false;
  useEffect(() => {
    audioPlayer.current ? playing(audioPlayer.current.muted) : playing(false);
  }, [d]);

  useEffect(() => {
    player ? toggleMute(true) : toggleMute(false);
  }, [player])

  return (
    <div className="relative select-none">
      <div className="lg:hidden fixed w-full text-xs sm:text-sm md:text-md  h-full bg-[rgb(0,204,255)] z-[100] text-black flex flex-col justify-center items-center uppercase font-bold tracking-wider">
        <div>this device size is not supported.</div>
        <div>please visit this site on an ipad or larger</div>
        <br />
        <div className="hidden italic text-stone-700 md:inline-flex">if on ipad please rotate device to landscape position</div>
      </div>
      {overlay && <Overlay hidden={hidden} hide={hide} enter={enter} />}
      {player && <Player setPlayer={setPlayer} title={title} src={src} type={type} imgs={imgd} />}
      <playerContext.Provider value={initPlayer}>
        <Navigation toggleMute={toggleMute} mute={sound} />
      </playerContext.Provider>
      
      <video
       src={bgSrc}
       autoPlay
       muted
       loop
       className="w-full aspect-video">
      </video>
      <audio onEnded={shuffle} ref={audioPlayer} src="/assets/main/327.mp3" className="hidden"></audio>
    </div>
  )
}

export default Background;

/*  
  [x] Rearrange Other Works
  [x] Real Descriptions
  [x] Music
  [x] Image gallery
  [x] Mobile Version
  [ ] About me update
 */