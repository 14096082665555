import Background from "./lib/components/Background"

const App = () => {
  document.title = "Wahyu Syawal"
  return (
    <>
      <Background />
    </>
  );
}

export default App;
