const Cover = "/assets/otherworks/character design/year2_semester5_character1/cover.jpg";
const Img2 = "/assets/otherworks/character design/year2_semester5_character1/2.jpg";
const Img3 = "/assets/otherworks/character design/year2_semester5_character1/3.jpg";
const Img4 = "/assets/otherworks/character design/year2_semester5_character1/4.jpg";
const Img5 = "/assets/otherworks/character design/year2_semester5_character1/5.jpg";

const y2s5c1 = {
  title: "2-5: Character 1",
  images: [
    Cover, Img2, Img3, Img4, Img5
  ],
  files: 5,
  description: `My first class where i got my make my own stylized character.
  I was mostly having trouble with the technical sides on using photoshop but for some reason i had no trouble drawing compared to my previous class.
  Somewhere along this class i kind of regretted taking digital animation and should have gone to illustration.
  But i constantly heard from many of my teachers that they change their careers from a animator to a illustrator, modeller to animator, etc.
  So after sometime i just reminded myself to go with the flow. 
  In otehr words, im enjoying animation right now, but if i still have the bug of being an illustrator in the future, its not an impossible task to switch those careers.`
}

/**
 * @type Array<FileProps>
 */
export const y2s5c1_files = [
  {
    id: "ow_cd_y2c1-cover",
    title: "Cover.jpg",
    date: "2019-01-01",
    description: y2s5c1.description,
    size: "1.5 MB",
    thumb: Cover,
    src: null
  },
  {
    id: "ow_cd_y2c1-2",
    title: "2.jpg",
    date: "2019-01-01",
    description: y2s5c1.description,
    size: "1.5 MB",
    thumb: Img2,
    src: null
  },
  {
    id: "ow_cd_y2c1-3",
    title: "3.jpg",
    date: "2019-01-01",
    description: y2s5c1.description,
    size: "1.5 MB",
    thumb: Img3,
    src: null
  },
  {
    id: "ow_cd_y2c1-4",
    title: "4.jpg",
    date: "2019-01-01",
    description: y2s5c1.description,
    size: "1.5 MB",
    thumb: Img4,
    src: null
  },
  {
    id: "ow_cd_y2c1-5",
    title: "5.jpg",
    date: "2019-01-01",
    description: y2s5c1.description,
    size: "1.5 MB",
    thumb: Img5,
    src: null
  },
]

export default y2s5c1;