const AboutMe = "/assets/main/subtext_aboutme.png";
const Background = "/assets/main/background.mp4";
const Specialization = "/assets/main/subtext_specialization.png";
const OtherWorks =  "/assets/main/subtext_otherworks.png";
const Overlay = "/assets/main/overlay/1. whole page.jpg";
const OverlayMain = "/assets/main/overlay/main.png";
const ContactA = "/assets/main/overlay/contacts - ahmad.png"
const ContactW = "/assets/main/overlay/contacts - wahyu.png"


export {
  AboutMe,
  Background,
  Specialization,
  OtherWorks,
  Overlay,
  OverlayMain,
  ContactA,
  ContactW
};