const mg_final = "/assets/otherworks/motion graphic/MotionGraphic_final.mp4";
const mg_thumb = "/assets/otherworks/motion graphic/mg_thumb.png";
const vans = "/assets/otherworks/motion graphic/VANScommercial_final.mp4";
const vans_thumb = "/assets/otherworks/motion graphic/vans_thumb.png";

const motionFinal = {
  title: "Motion Graphics",
  images: [mg_thumb],
  videos: [mg_final],
  files: 1,
  description: `During my final year, i had a video editing class where i first learnt after effects.
  Prior to this, I was already used to another editing software, Sony Vegas Pro. So i didn't really have any intention of learning it.
  However, when got briefed about our first assignment and we got to learning motion graphic, it caught my attention.
  I was already used to editing these kind of videos during highschool so i finished this assignment within a week.
  Looking back at it, it's really really corny but i can't deny it was my main creative output when i first got into video editing before i got into college. `
}
const motionFile = {
  id: "motiongraphic-file",
  title: "Motion Graphic",
  date: "2020-04-01",
  size: "59.41 MB",
  thumb: mg_thumb,
  src: mg_final,
  description:`During my final year, i had a video editing class where i first learnt after effects.
  Prior to this, I was already used to another editing software, Sony Vegas Pro. So i didn't really have any intention of learning it.
  However, when got briefed about our first assignment and we got to learning motion graphic, it caught my attention.
  I was already used to editing these kind of videos during highschool so i finished this assignment within a week.
  Looking back at it, it's really really corny but i can't deny it was my main creative output when i first got into video editing before i got into college. `
}

const vansFile = {
  id: "vans_commercial-file",
  title: "VANS Commercial",
  thumb: vans_thumb,
  src: vans,
  date: "2020-03-01",
  size: "68.98 MB",
  description: `During my video editing class, we had a final assignment where had to film footage and make a commercial trying to sell a product.
  Me and my friends was newly into skating at the time so without hesitation i decided to target VANS.
  Just like any skate video, i wanted it to be sick but since we were new, none of us could really land a sick trick or even ollie over an obsticle.
  After days of filming and archiving footage, i finally just decided to embrace the fact that we weren't the only ones new to this and had trouble when starting out. 
  So the VANS video really turned out to be me, my friends and a bunch of skaters at the park falling straight on the concrete while they were all wearing VANS with the motto "just keep trying".`
}


const motionGraphic = {
  title: "Motion Graphics",
  images: [vans_thumb],
  files: 2,
  description: `Motion Graphics Placeholder Text`
}

export default motionGraphic;

export {
  motionFinal,
  motionFile,
  vansFile
}