import { Dispatch, SetStateAction, useState } from "react";
import Sidebar from "../Parts/Sidebar";
import Titlebar from "../Parts/Titlebar";

import { twoD, threeD, latestreel } from "../../assets/spec";
import File from "../Parts/File";
import Divider from "../Parts/Divider";

interface Props {
  view: string;
  setView: Dispatch<SetStateAction<string>>;
  close: Dispatch<SetStateAction<boolean>>;
  setFull: () => void;
}

export interface Item {
  id: string;
  title: string;
  thumb: string;
  description: string;
  src: any;
  date: string;
  size: string;
}

const Specialization: React.FC<Props> = ({ view, setView, close, setFull }) => {

  // Navigation
  const [tree, updateTree] = useState([{name: "Specializations", path: "home"}]);
  const changeDir = (name: string, path: string) => {
    if (path === "home") updateTree([{name: "Specializations", path: "home"}]);
    updateTree([...tree, {name, path}]);
    setView(path);
  }
  // const back = (item: { name: string; path: string }) => {
  //   const newTree = [...tree];
  //   newTree.pop();
  //   updateTree(newTree);
  //   setView(newTree[newTree.length - 1].path);
  // }
  
  // File / Folder Description [Sidebar]
  const [hover, changeHover] = useState("");
  const [item, setItem] = useState<Item>();


  // useEffect(() => console.log(view), [view]);
  return (
    <div className="flex w-full h-full">
      <Titlebar close={close} setFull={setFull} view={view}  />
      <div className={`px-4 ${view === "home" ? "w-full" : "w-full xl:w-[70%]"}`}>
        <div className={`flex xl:mt-5 mx-5 ${view !== "home" ? "" : ""}`}>
          <div className="w-full text-2xl font-bold tracking-widest">Specializations</div>
        </div>
        <hr className="my-6 mr-10 border-slate-500" />
        {/* <Breadcrumb tree={tree} back={back} className="mb-2 ml-4" /> */}
        <div className="flex w-full max-h-[32rem] oveflow-y-auto">
          {view === "home" && <Home setView={changeDir} changeHover={changeHover} setItem={setItem} />}
          {view === "2d" && <Folder2D setView={changeDir} changeHover={changeHover} setItem={setItem} />}
          {view === "3d" && <Folder3D setView={changeDir} changeHover={changeHover} setItem={setItem} />}
        </div>
      </div>
      <div className="hidden xl:flex flex-col w-[30%] bg-gray-800 pt-5 lg:min-h-max">
        <Sidebar hover={hover} changeHover={changeHover} item={item} folderItem={undefined} />
      </div>
    </div>
  )
  // return (

    // <div className="relative flex flex-col w-full">
    //   <Titlebar close={close} setFull={setFull} view={view} />
    //   <div className="flex justify-end w-full">
    //     <div className="flex flex-col w-full xl:w-[75%] pr-2 pl-9 h-[45rem]">
    //       <div className="xl:mt-10" />
    //       <div className="w-full text-2xl font-bold tracking-widest">Specializations</div>
    //       <hr className="my-6 mr-10 border-slate-500" />
    //       <Breadcrumb tree={tree} back={back} className="mb-2" />
    //       {view === "home" && <Home setView={changeDir} changeHover={changeHover} setItem={setItem} />}
    //       {view === "2d" && <Folder2D setView={changeDir} changeHover={changeHover} setItem={setItem} />}
    //       {view === "3d" && <Folder3D setView={changeDir} changeHover={changeHover} setItem={setItem} />}
    //     </div>
    //     <div className="hidden xl:flex flex-col w-[25%] bg-gray-800 pt-5 lg:min-h-max">
    //       <Sidebar hover={hover} changeHover={changeHover} item={item} folderItem={undefined} />
    //     </div>
    //   </div>
    // </div>
  // )
}

interface HomeProps {
  changeHover: Dispatch<SetStateAction<string>>;
  setItem: Dispatch<SetStateAction<Item | undefined>>;
  setView: (name: string, path: string) => void;
}

const Home: React.FC<HomeProps> = ({ setView, changeHover, setItem }) => {
  return (
    <div className="flex flex-col overflow-y-auto">
      {/* Latest Reel */}
      <div className="grid grid-cols-3 mb-5 ml-2 mr-5 gap-x-5 gap-y-5">
        <Divider>Latest Reel</Divider>
        <File item={latestreel} title={latestreel.title} className="col-span-3 h-fit" hover={changeHover} setItem={setItem} />
        
        {/* 2d animations */}
        <Divider>2D Animations</Divider>
        {twoD.map((item, index) => (
          <File item={item} key={index} title={item.title} className="h-fit [32rem]" hover={changeHover} setItem={setItem} />
          ))}
        <div className="w-full my-12" />
        
        {/* 3d animations */}
        {/* <Divider>3D Animations</Divider>
        {threeD.map((item, index) => (
          <File item={item} title="" key={index} className="h-fit [32rem]" hover={changeHover} setItem={setItem} />
        ))} */}
      </div>
    </div>
  )
}

const Folder2D: React.FC<HomeProps> = ({ setView, changeHover, setItem }) => {

  return (
    <div className="grid grid-cols-3 my-5 mr-5 gap-x-5 gap-y-10">
      <File item={twoD[0]} title="Latest Reel" className="col-span- h-[32rem]" hover={changeHover} setItem={setItem} />
      <File item={twoD[1]} title="Latest Reel" className="col-span- h-[32rem]" hover={changeHover} setItem={setItem} />
      <File item={twoD[2]} title="Latest Reel" className="col-span- h-[32rem]" hover={changeHover} setItem={setItem} />
    </div>
  )
}

const Folder3D: React.FC<HomeProps> = ({ setView, changeHover, setItem }) => {
  return (
    <div className="grid grid-cols-3 my-5 mr-5 gap-x-5 gap-y-10">
      {threeD.map((item, index) => (
        <File item={item} title="" key={index} className="h-[32rem]" hover={changeHover} setItem={setItem} />
      ))}
    </div>
  )
}

export default Specialization;