const root = "/assets/otherworks/vivid";
const img2 = `${root}/2.0 (cover).jpg`;
const img2_1 = `${root}/2.1.jpg`;
const img4 = `${root}/4.0 (cover).jpg`;
const img4_1 = `${root}/4.1.jpg`;
const img5 = `${root}/5.0 (cover).jpg`;
const img5_1 = `${root}/5.1.jpg`;
const img6 = `${root}/6.0 (cover).jpg`;
const img6_1 = `${root}/6.1.jpg`;
const img7 = `${root}/7.0 (cover).jpg`;
const img7_1 = `${root}/7.1.jpg`;
const img8 = `${root}/8.0 (cover).jpg`;
const img8_1 = `${root}/8.1.jpg`;
const img9 = `${root}/9.0 (cover).jpg`;
const img9_1 = `${root}/9.1.jpg`;
const img10 = `${root}/10.0 (cover).jpg`;
const img10_1 = `${root}/10.1.jpg`;
const img11 = `${root}/11.0 (cover).jpg`;
const img11_1 = `${root}/11.1.jpg`;
const img12 = `${root}/12.0 (cover).jpg`;
const img12_1 = `${root}/12.1.jpg`;
const img13 = `${root}/13.0 (cover).jpg`;
const img13_1 = `${root}/13.1.jpg`;
const img14 = `${root}/14.0 (cover).jpg`;
const img14_1 = `${root}/14.1.jpg`;
const img15 = `${root}/15.jpg`;
const img16 = `${root}/16.jpg`;
const img17 = `${root}/17.jpg`;
const img18 = `${root}/18.jpg`;
const img19 = `${root}/19.jpg`;
const img20 = `${root}/20.jpg`;
const img21 = `${root}/21.jpg`;
const img22_1 = `${root}/22.1.jpg`;
const img22 = `${root}/22.jpg`;
const img23 = `${root}/23.0 (cover).jpg`;
const img23_1 = `${root}/23.1.jpg`;
const img24  = `${root}/24 (cover).jpg`;
const img25 = `${root}/25.jpg`;
const img26 = `${root}/26.jpg`;
const vid_thumb = `${root}/27.jpg`;
const vid = `${root}/27.mp4`;

const vivid = {
  title: "vivid",
  images: [img2],
  files: 40,
  description: `VIVID was a project I started somewhere during my first year of my diploma. 
  I bought affordable button ups and just paint whatever I felt like. 
  The reason why I kept them for so long is because I failed to put effort on an efficient method on selling them. 
  However, I don't really consider it to be a complete failure because I did somewhat improve my painting skills and got to do some commissions for my close friends and collected some decent amount of money for myself.`,
}
/**
 * @type  Array<FileProps>
 */
export const vivid_files = [
  {
    id: "vivid-img2",
    title: "2.jpg",
    thumb: img2,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img2_1",
    title: "2_1.jpg",
    thumb: img2_1,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img4",
    title: "4.jpg",
    thumb: img4,
    src:null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img4_1",
    title: "4_1.jpg",
    thumb: img4_1,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img5",
    title: "5.jpg",
    thumb: img5,
    src:null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img5_1",
    title: "5_1.jpg",
    thumb: img5_1,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img6",
    title: "6.jpg",
    thumb: img6,
    src:null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img6_1",
    title: "6_1.jpg",
    thumb: img6_1,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img7",
    title: "7.jpg",
    thumb: img7,
    src:null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img7_1",
    title: "7_1.jpg",
    thumb: img7_1,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img8",
    title: "8.jpg",
    thumb: img8,
    src:null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img8_1",
    title: "8_1.jpg",
    thumb: img8_1,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img9",
    title: "9.jpg",
    thumb: img9,
    src:null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img9_1",
    title: "9_1.jpg",
    thumb: img9_1,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img10",
    title: "10.jpg",
    thumb: img10,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img10_1",
    title: "10_1.jpg",
    thumb: img10_1,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img11",
    title: "11.jpg",
    thumb: img11,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img11_1",
    title: "11_1.jpg",
    thumb: img11_1,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img12",
    title: "12.jpg",
    thumb: img12,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img12_1",
    title: "12_1.jpg",
    thumb: img12_1,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img13",
    title: "13.jpg",
    thumb: img13,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img13_1",
    title: "13_1.jpg",
    thumb: img13_1,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img14",
    title: "14.jpg",
    thumb: img14,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img14_1",
    title: "14_1.jpg",
    thumb: img14_1,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img15",
    title: "15.jpg",
    thumb: img15,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img16",
    title: "16.jpg",
    thumb: img16,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img17",
    title: "17.jpg",
    thumb: img17,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img18",
    title: "18.jpg",
    thumb: img18,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img19",
    title: "19.jpg",
    thumb: img19,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img20",
    title: "20.jpg",
    thumb: img20,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img21",
    title: "21.jpg",
    thumb: img21,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img22_1",
    title: "22_1.jpg",
    thumb: img22_1,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img22",
    title: "22.jpg",
    thumb: img22,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img23",
    title: "23.jpg",
    thumb: img23,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img23_1",
    title: "23_1.jpg",
    thumb: img23_1,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img24",
    title: "24.jpg",
    thumb: img24,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img25",
    title: "25.jpg",
    thumb: img25,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vivid-img26",
    title: "26.jpg",
    thumb: img26,
    src: null,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
  {
    id: "vid1",
    title: "27.mp4",
    thumb: vid_thumb,
    src: vid,
    description: vivid.description,
    size: "424 KB",
    date: "2019-08-21"
  },
]


export default vivid;



