const Cover = "/assets/otherworks/character design/year2_semester5_character2/cover.jpg";
const Img2 = "/assets/otherworks/character design/year2_semester5_character2/2.jpg";
const Img3 = "/assets/otherworks/character design/year2_semester5_character2/3.jpg";
const Img4 = "/assets/otherworks/character design/year2_semester5_character2/4.jpg";
const Img5 = "/assets/otherworks/character design/year2_semester5_character2/5.jpg";
const Img6 = "/assets/otherworks/character design/year2_semester5_character2/6.jpg";
const Img7 = "/assets/otherworks/character design/year2_semester5_character2/7.jpg";

const y2s5c2 = {
  title: "2-5: Character 2",
  images: [
    Cover, Img2, Img3, Img4, Img5, Img6, Img7
  ],
  files: 7,
  description: `This was mostly my proudest outcome i made from a class.
  From the start i was already outside my comfort zone by agreeing to go towards the industrial style.
  Another challenging part was that it was a group project, meaning all of our characters had to look like from the same universe.
  So i definetely learnt a lot about communication within a creative project in this class.
  On top of that, my understanding of Photoshop from a technical point was slowly but surely becoming more solid.`
}


export const y2s5c2_files = [
  {
    id: "ow_cd_y2c2-cover",
    title: "Cover.jpg",
    date: "2019-01-01",
    description: y2s5c2.description,
    size: "1.5 MB",
    thumb: Cover,
    src: null
  },
  {
    id: "ow_cd_y2c2-2",
    title: "2.jpg",
    date: "2019-01-01",
    description: y2s5c2.description,
    size: "1.5 MB",
    thumb: Img2,
    src: null
  },
  {
    id: "ow_cd_y2c2-3",
    title: "3.jpg",
    date: "2019-01-01",
    description: y2s5c2.description,
    size: "1.5 MB",
    thumb: Img3,
    src: null
  },
  {
    id: "ow_cd_y2c2-4",
    title: "4.jpg",
    date: "2019-01-01",
    description: y2s5c2.description,
    size: "1.5 MB",
    thumb: Img4,
    src: null
  },
  {
    id: "ow_cd_y2c2-5",
    title: "5.jpg",
    date: "2019-01-01",
    description: y2s5c2.description,
    size: "1.5 MB",
    thumb: Img5,
    src: null
  },
  {
    id: "ow_cd_y2c2-6",
    title: "6.jpg",
    date: "2019-01-01",
    description: y2s5c2.description,
    size: "1.5 MB",
    thumb: Img6,
    src: null
  },
  {
    id: "ow_cd_y2c2-7",
    title: "7.jpg",
    date: "2019-01-01",
    description: y2s5c2.description,
    size: "1.5 MB",
    thumb: Img7,
    src: null
  }
]

export default y2s5c2;