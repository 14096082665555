import type { Dispatch, SetStateAction } from "react";

interface Props {
  close: Dispatch<SetStateAction<boolean>>;
  setFull: () => void;
  view: string;
  title?: string;
  maximize?: boolean;
  className?: string;
}


const Titlebar: React.FC<Props> = ({ close, setFull, view, title, maximize, className }) => {

  return (
    <div className={`absolute top-0 left-0 flex items-end justify-end w-full h-8 pb-px bg-transparent border-none gap-x-2 ${className}`} >
      <div className="pb-1 pl-4 mr-auto">{title}</div>
      <span className={`cursor-pointer ${maximize ?  "hidden" : ""}`} onClick={setFull}>
        <svg className="-translate-y-0.5" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.5em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="none"><path d="M3.75 3a.75.75 0 0 0-.75.75V5.5a.5.5 0 0 1-1 0V3.75C2 2.784 2.784 2 3.75 2H5.5a.5.5 0 0 1 0 1H3.75zM10 2.5a.5.5 0 0 1 .5-.5h1.75c.966 0 1.75.784 1.75 1.75V5.5a.5.5 0 0 1-1 0V3.75a.75.75 0 0 0-.75-.75H10.5a.5.5 0 0 1-.5-.5zM2.5 10a.5.5 0 0 1 .5.5v1.75c0 .414.336.75.75.75H5.5a.5.5 0 0 1 0 1H3.75A1.75 1.75 0 0 1 2 12.25V10.5a.5.5 0 0 1 .5-.5zm11 0a.5.5 0 0 1 .5.5v1.75A1.75 1.75 0 0 1 12.25 14H10.5a.5.5 0 0 1 0-1h1.75a.75.75 0 0 0 .75-.75V10.5a.5.5 0 0 1 .5-.5z" fill="currentColor"/></g></svg>
      </span>
      <span onClick={() => close(false)} className="cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.75em" height="1.75em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M764.288 214.592L512 466.88L259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512L214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z"/></svg>
      </span>
    </div>
  )
}

export default Titlebar;