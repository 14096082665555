import { Dispatch, SetStateAction, useState } from "react";
import { Item } from "../Specialization";
import { FolderItem } from "../Winbox";

interface Props {
  hover: string;
  changeHover: Dispatch<SetStateAction<string>>;
  item: Item | undefined;
  folderItem: FolderItem | undefined;
}

const Sidebar: React.FC<Props> = ({ hover, item, folderItem }) => {
  const [mouseover, setMouseover] = useState(false);
  return (
    <div className="flex flex-col mt-5 px-7">
      <div className="text-xl font-medium">
        Item Preview
      </div>
        <hr className="w-full my-5 border-slate-500" />
        <Thumbnail hover={hover} mouseover={mouseover} setMouseover={setMouseover} item={item ? item : undefined} folderItem={folderItem ? folderItem : undefined} />
        <hr className="w-full my-5 border-slate-500" />
        <span className="text-xl font-bold tracking-wider uppercase">{item ? item.title : folderItem ? folderItem.title : hover}</span>
        <br />
        <span className="!overflow-y-auto h-80 text-2xs font-semibold tracking-widest text-gray-500">
          {item ? item.description : folderItem ? folderItem.description : "Hover over a file for more information"}
        </span>
    </div>
  )
}

interface ThumbnailProps {
  setMouseover: Dispatch<SetStateAction<boolean>>
  hover: string;
  mouseover: boolean;
  item: Item | undefined;
  folderItem: FolderItem | undefined;
}

const Thumbnail: React.FC<ThumbnailProps> = ({ hover, setMouseover, mouseover, item, folderItem }) => (
  <>

    <img
     src={item ? item.thumb : folderItem ? folderItem.images[0] : ""}
     alt={hover}
     onMouseEnter={() => setMouseover(true)} 
     onMouseLeave={() => setMouseover(false)} 
     className={`duration-500 ${mouseover ? "!-translate-y-2" : ""} aspect-video object-contain object-top`}
     />
    {/* } */}
  </>
)

export default Sidebar;