import { Dispatch, SetStateAction } from "react";

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}

const AboutMe: React.FC<Props> = ({ open, setOpen }) => {
  return (
    <div className={`modal ${open ? "modal-open" : ""} backdrop-blur`}>
      <div className="relative flex flex-col max-w-screen-md p-0 m-0 text-black rounded-r-none modal-box">
        <div className="fixed top-0 right-0 flex justify-end w-full">
          <span className="btn btn-ghost" onClick={() => setOpen(false)}>
            ✕
          </span>
        </div>
        <img src="/assets/aboutme.jpg" alt="about-me" />
        {/* <p className="select-text">
          Whats up :) My name is Wahyu Syawal. I'm an artist based in Malaysia.
          <br />
          <span className="px-1 bg-amber-400">I mainly do 3D animation</span> but also make other stuff such as video editing, character design, 2D animation and concept art.
          <br />
          <br />
          I'm really really not good at talking <span className="px-1 bg-pink-700">about myself.</span> hopefully, you get to know me by going through my work inside this site.
          <br />
          <br />
          <span className="px-2 text-xl font-bold uppercase bg-pink-800">A Little Summary</span>
          <br />
          Took a diploma in animation and studied in The One Academy in Bandar Sunway. 
          I learnt <span className="px-px bg-green-400">many skills</span> but the only project worth mentioning is the short film project I was involved in at the end of the year.
          <br />
          I collaborated with a team of illustrators, concept artists and 3D modellers and as for my contribution to the team, I was a lead animator and animated 16 shots in total.
          <br />
          When I'm not infront of my computer making art, I'm either working out, skating, painting traditionally or watching a movie.

          <br />
          <br />
          <span className="text-xl font-bold uppercase bg-[#FFE5B4] px-2 py-1">
            Why I F<span className="py-0 my-0 leading-none bg-black">ucking</span> Love Animation
          </span>
          <br />
          <br />
          It is <i><b>THE</b></i> best story telling medium
          <br />
          <br />
          As we all know, animation is just a sequence of images creating the illusion of movement. As the years and years go by, animation is a medium that continues to evolve visually and is never stuck in <b>ONE WAY TO BE TOLD / MADE.</b>
          It is also an extremely flexible medium.
          <br />
          Whatever art you're into, music, dancing, sculpting, video editing, etc. you can still be a part of animation so weather its a video game or film pipeline, I want to be involved to tell stories.
          <br />
          <br />
          <span className="px-2 py-1 text-xl font-bold uppercase bg-green-500">
            My Current Skill Sets
          </span>
          <br />
          <br />
          {
          []
          }
        </p> */}
      </div>
    </div>
  )
};

export default AboutMe;