const Cover = "/assets/otherworks/character design/year2_semester5_character3/cover.jpg";
const Img2 = "/assets/otherworks/character design/year2_semester5_character3/2.jpg";
const Img3 = "/assets/otherworks/character design/year2_semester5_character3/3.jpg";
const Img4 = "/assets/otherworks/character design/year2_semester5_character3/4.jpg";
const Img5 = "/assets/otherworks/character design/year2_semester5_character3/5.jpg";
const Img6 = "/assets/otherworks/character design/year2_semester5_character3/6.jpg";
const Img7 = "/assets/otherworks/character design/year2_semester5_character3/7.jpg";
const Img8 = "/assets/otherworks/character design/year2_semester5_character3/8.jpg";
const Img9 = "/assets/otherworks/character design/year2_semester5_character3/9.jpg";

const y2s5c3 = {
  title: "2-5: Character 3",
  images: [
    Cover, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9
  ],
  files: 9,
  description: `The final class of character design of my second year.
  At the start of this class, i thought i was the shit after finishing previous character design classes.
  Until we were introduced to photobashing, and that workflow absolutely humbled me mentally.
  It was the most difficult workflow i ever learnt at the time.
  I started off with the characters and the weapons and week after week i was redoing it because i just personally wasn't satisfied.
  I gave up sleep and food because i knew i wanted to improve but at the back of my mind i knew that it wasn't up to standard.
  Then came to design the vehicle, i slowly got the hang of it.
  I had no issues making the thumbnails/rough deisgns compared to the characters and weapon but i was still having trouble with the polishing.
  On the last two assignments, the environment and creature, for some weird reason i was in a flow state.
  I managed to finish the environment within a week and the creatures within 2-3 weeks.
  Looking back at this project right now, it is average overall, with the weapon and characters looking like shit, but i definetely learnt more than i expected.`
}

export const y2s5c3_files = [
  {
    id: "ow_cd_y2c3-cover",
    title: "Cover.jpg",
    date: "2019-01-01",
    description: y2s5c3.description,
    size: "1.5 MB",
    thumb: Cover,
    src: null
  },
  {
    id: "ow_cd_y2c3-2",
    title: "2.jpg",
    date: "2019-01-01",
    description: y2s5c3.description,
    size: "1.5 MB",
    thumb: Img2,
    src: null
  },
  {
    id: "ow_cd_y2c3-3",
    title: "3.jpg",
    date: "2019-01-01",
    description: y2s5c3.description,
    size: "1.5 MB",
    thumb: Img3,
    src: null
  },
  {
    id: "ow_cd_y2c3-4",
    title: "4.jpg",
    date: "2019-01-01",
    description: y2s5c3.description,
    size: "1.5 MB",
    thumb: Img4,
    src: null
  },
  {
    id: "ow_cd_y2c3-5",
    title: "5.jpg",
    date: "2019-01-01",
    description: y2s5c3.description,
    size: "1.5 MB",
    thumb: Img5,
    src: null
  },
  {
    id: "ow_cd_y2c3-6",
    title: "6.jpg",
    date: "2019-01-01",
    description: y2s5c3.description,
    size: "1.5 MB",
    thumb: Img6,
    src: null
  },
  {
    id: "ow_cd_y2c3-7",
    title: "7.jpg",
    date: "2019-01-01",
    description: y2s5c3.description,
    size: "1.5 MB",
    thumb: Img7,
    src: null
  },
  {
    id: "ow_cd_y2c3-8",
    title: "8.jpg",
    date: "2019-01-01",
    description: y2s5c3.description,
    size: "1.5 MB",
    thumb: Img8,
    src: null
  },
  {
    id: "ow_cd_y2c3-9",
    title: "9.jpg",
    date: "2019-01-01",
    description: y2s5c3.description,
    size: "1.5 MB",
    thumb: Img9,
    src: null
  }
];

export default y2s5c3;