const edits_thumb = "/assets/otherworks/just for fun/VideoEdits/thumb.png";
const edits_1 = "/assets/otherworks/just for fun/VideoEdits/1.mp4";
const edits_1_thumb = "/assets/otherworks/just for fun/VideoEdits/1.png";
const edits_2 = "/assets/otherworks/just for fun/VideoEdits/2.mp4";
const edits_2_thumb = "/assets/otherworks/just for fun/VideoEdits/2.png";
const edits_3 = "/assets/otherworks/just for fun/VideoEdits/3.mp4";
const edits_3_thumb = "/assets/otherworks/just for fun/VideoEdits/3.png";
const edits_4 = "/assets/otherworks/just for fun/VideoEdits/4.mp4";
const edits_4_thumb = "/assets/otherworks/just for fun/VideoEdits/4.png";
const edits_5 = "/assets/otherworks/just for fun/VideoEdits/5.mp4";
const edits_5_thumb = "/assets/otherworks/just for fun/VideoEdits/5.png";
const edits_6 = "/assets/otherworks/just for fun/VideoEdits/6.mp4";
const edits_6_thumb = "/assets/otherworks/just for fun/VideoEdits/6.png";
const edits_7 = "/assets/otherworks/just for fun/VideoEdits/7.mp4";
const edits_7_thumb = "/assets/otherworks/just for fun/VideoEdits/7.png";
const edits_8 = "/assets/otherworks/just for fun/VideoEdits/8.mp4";
const edits_8_thumb = "/assets/otherworks/just for fun/VideoEdits/8.png";
const edits_9 = "/assets/otherworks/just for fun/VideoEdits/9.mp4";
const edits_9_thumb = "/assets/otherworks/just for fun/VideoEdits/9.png";
const edits_10 = "/assets/otherworks/just for fun/VideoEdits/10.mp4";
const edits_10_thumb = "/assets/otherworks/just for fun/VideoEdits/10.png";

const videoEdits = {
  title: "Video Edits",
  images: [
    edits_thumb,
  ],
  videos: [
    edits_1, edits_2, edits_3, edits_4, edits_5, edits_6,
  ],
  files: 6,
  description: `During highschool, i encountered a video editing software called Sony Vegas Pro.
  A lot of my influence came from Instagram editors like InspiredIsland and 6beanerish and i mostly just tried to mimick their style.
  Obviously i could never reach their level but it was fun doing it since there was no expectations of me making good videos.
  Looking back at it, i was really in flow state where i just contantly make videos after videos. 
  The ones shown here are the best ones amongst almost a hundred videos in archive.`,
};

/**
 * @type Array<FileProps>
 */
export const video_files = [
  {
    id: "edit-1",
    title: "just4fun_1",
    description: videoEdits.description,
    thumb: edits_1_thumb,
    src: edits_1,
    date: "2021-03-23",
    size: "62.5 MB",
  },
  {
    id: "edit-2",
    title: "just4fun_2",
    description: videoEdits.description,
    thumb: edits_2_thumb,
    src: edits_2,
    date: "2021-03-23",
    size: "62.5 MB",
  },
  {
    id: "edit-3",
    title: "just4fun_3",
    description: videoEdits.description,
    thumb: edits_3_thumb,
    src: edits_3,
    date: "2021-03-23",
    size: "62.5 MB",
  },
  {
    id: "edit-4",
    title: "just4fun_4",
    description: videoEdits.description,
    thumb: edits_4_thumb,
    src: edits_4,
    date: "2021-03-23",
    size: "62.5 MB",
  },
  {
    id: "edit-5",
    title: "just4fun_5",
    description: videoEdits.description,
    thumb: edits_5_thumb,
    src: edits_5,
    date: "2021-03-23",
    size: "62.5 MB",
  },
  {
    id: "edit-6",
    title: "just4fun_6",
    description: videoEdits.description,
    thumb: edits_6_thumb,
    src: edits_6,
    date: "2021-03-23",
    size: "62.5 MB",
  },
  {
    id: "edit-7",
    title: "just a couple movie recommendations",
    description: videoEdits.description,
    thumb: edits_7_thumb,
    src: edits_7,
    date: "2021-03-23",
    size: "62.5 MB",
  },
  {
    id: "edit-8",
    title: "just4fun_6",
    description: videoEdits.description,
    thumb: edits_8_thumb,
    src: edits_8,
    date: "2021-03-23",
    size: "62.5 MB",
  },
  {
    id: "edit-9",
    title: "just4fun_7",
    description: videoEdits.description,
    thumb: edits_9_thumb,
    src: edits_9,
    date: "2021-03-23",
    size: "62.5 MB",
  },
  {
    id: "edit-10",
    title: "just4fun_8",
    description: videoEdits.description,
    thumb: edits_10_thumb,
    src: edits_10,
    date: "2021-03-23",
    size: "62.5 MB",
  },
]