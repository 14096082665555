const BombHillImage = "/assets/specializations/2dAnimation/BombHill.png";
const BombHill = "/assets/specializations/2dAnimation/BombHill.mp4";
const Intro2AnimationImage = "/assets/specializations/2dAnimation/Intro2Animation.png";
const Intro2Animation = "/assets/specializations/2dAnimation/Intro2Animation.mp4";
const RoachAintDeadImage = "/assets/specializations/2dAnimation/RoachaintDead.png";
const RoachAintDead = "/assets/specializations/2dAnimation/RoachaintDead.mp4";

const Early21Thumb = "/assets/specializations/3dAnimation/DemoReel_early21.png";
const Late20Thumb = "/assets/specializations/3dAnimation/DemoReel_late20.png";
const Mid20Thumb = "/assets/specializations/3dAnimation/DemoReel_mid20.png";
const Early21 = "/assets/specializations/3dAnimation/DemoReel_early21.mp4";
const Late20 = "/assets/specializations/3dAnimation/DemoReel_late20.mp4";
const Mid20 = "/assets/specializations/3dAnimation/DemoReel_mid20.mp4";

const LatestReel = "/assets/specializations/latest reel.mp4";
const LatestReelThumb = "/assets/specializations/latest reel.jpg";


const twoD = [
  { 
    id: "two1",
    title: "BombHill", 
    thumb: BombHillImage, 
    description: `During the Covid Lockdown, me and my friends got into skating to kill time.
    Since the parks are close, we spot out for empty parking lots and flat ground to practise.
    I can talk for hours and write a whole essay on why skating means a lot to me..
    but to keep it short, it helped me a lot as an artist when it comes to expression.
    Eventually, i wanted to animate a skating clip.
    To my surpise, from youtube, dailymotion and vimeo i didnt see any animated skating clip from countless of 2d or 3d demoreels.
    I maybe spotted one or two but there just wasnt enough for me to use as inspiration at the time.
    I didnt know whether to animate it in 2d or 3d and I spent days thinking of an efficient method.
    Eventually i said fuck it, opened photoshop and worked on it. 
    and to be honest, i liked the way it turned out :)`, 
    src: BombHill,
    size: "2.94 MB",
    date: "2020-69-01"
  },
  {
    id: "two2",
    title: "Intro2Animation",
    thumb: Intro2AnimationImage,
    description: `The first class in my diploma that introduced me to the process of hand drawn animation. 
    These 3 animations was the 3 major submissions of the whole course and looking back at it, it's not really something i'm proud of.
    My drawing skills was average so i had very little trouble getting down poses but getting down the timing was hard for me at the time.
    My teacher heavily taught us to focus on the story more than the animation of the clip. Something that i wasn't really good at.
    We were given a week in advance to pitch each of our animation clips and my stories were either taken or boring.
    What pissed me off is that i didnt know how to come up with better stories or ask for help on it. I only focused on nailing the animation.
    Eventually, when it came down to polishing the animation, i lost interest in it. 
    Sometimes when i look back at these animations, if only the story it was telling was more interesting, i would've cared about it all the way through.`,
    src: Intro2Animation,
    size: "7.04 MB",
    date: "2021-01-10"
  },
  {
    id: "two3",
    title: "RoachAintDead",
    thumb: RoachAintDeadImage,
    description: `A very small personal project I worked on during my final year.
    Just like any teenage boy i was a little emotional. The usual thought of lonliness, feeling stuck and having no place kept me up for a couple months.
    So i thought of using animation to vent.
    Looking back at it, I didnt really know what i was trying to achieve.. 
    At the back of my mind, i think it's empathy from other people or maybe giving myself a purpose for a small period of time.
    it really is a bit of a blur memory to me at this point.
    Eventually when i did finish it, nothing really significant happened and it didnt help me emotionally or mentally.
    But for some odd reason, this project kind of made me hope that i don't die before making something really really fucking good. 
    I dont know when that time will come or how long it would take me but i'll just keep making more stuff and hope for the best `,
    src: RoachAintDead,
    size: "11.76 MB",
    date: "2021-01-01"
  }
]

const threeD = [
  {   
    id: "three1",
    title: "Mid20",
    thumb: Mid20Thumb,
    description: `After finishing the 2d animation class, i straight away learnt animation on Autodesk Maya.
    I have to admit, i wasn't the best student. Although i tried really hard i wasn't capable of juggling animation with all the other subjects i was learning.
    My main issue was probably the graph editor. I was already starting to get comfortable doing hand drawn animation..
    So switching to 3d was a huge huge learning curve for me.
    Although i wasn't happy with the final outcome, I had every intention on improving the following semester.`,
    src: Mid20,
    size: "18.24 MB",
    date: "2021-01-10"
  },
  {   
    id: "three2",
    title: "Late20",
    thumb: Late20Thumb,
    description: `This was probably my worst semester in terms of animation.
    After the introduction class to 3d animation, we wasted no time and started to animate the human body.
    My weekly progress was constantly getting shit on by my teachers class after class. 
    Looking back, i knew the problem was from my workflow. From blocking to spline, my animation was not solid at all and my graph editor showed it.
    My teachers would always give me different methods on how to fix it but i was too stubborn to get out of my comfort zone.
    When came to the polishing stage, like my previous works, i lost interest.
    On the last day of submission, I knew I to change my mindset.`,
    src: Late20,
    size: "14.71 MB",
    date: "2021-01-10"
  },
  {   
    id: "three3",
    title: "Early21",
    thumb: Early21Thumb,
    description: `On my final year of animation, we did both body mechanics and acting animation.
    Through out the whole semester, my teacher would show the class his method on animating.
    Slowly but surely my understanding of the graph editor became more clear.
    I started taking bits and pieces of the animating methods from the current and previous teachers and became more comfortable with the workflow.
    Through trial and error i slowly became more burnt out towards the end of the semester.
    But what i realized is i was more better in body mechanics than acting scenes (which will later influence my demoreel during my final project) 
    The look of the final outcome is mediocre but i learnt more than i expected and i knew at that point taking animation was the right choice for me.`,
    src: Early21,
    size: "66.76 MB",
    date: "2021-01-10"
  },
]

const latestreel = {
  id: "latest",
  title: "Latest Reel",
  thumb: LatestReelThumb,
  description: `During my final year when making a short film as the final project, i took a look at my animation work i did through my diploma and thought,

  "nobody is gonna hire me with this shit"
  
  so i scrapped all my animation clips and started from scratch.`,
  src: LatestReel,
  size: "63.0 MB",
  date: "2021-12-16"

}

export {
  twoD,
  threeD,
  latestreel
}