import { useEffect, useState } from "react";
import ImageMap from "image-map";
import * as Assets from "../../assets/main";

import "./index.css";
// import 'winbox/src/css/winbox.css'
import Winbox from "../Winbox";
import AboutMe from "../AboutMe";

interface Props {
  toggleMute: (mute?: boolean) => void;
  mute: boolean;
}

const Navigation: React.FC<Props> = ({ toggleMute, mute }) => {
  const [hover, setHover] = useState("")
  const [box, openBox] = useState(false);
  const [about, openAbout] = useState(false);
  const [window, currWindow] = useState<"spec" | "othw">("spec");
  useEffect(() => {
    ImageMap("img[useMap]");
  })
  
  const enterBox = (type: "spec" | "othw") => {
    currWindow(type);
    openBox(true);
  }

  return (
    <div className="relative">
      <Winbox close={openBox} open={box} type={window} />
      {about && <AboutMe open={about} setOpen={openAbout} />}
      <img src={Assets.AboutMe} useMap="#main-image" alt="About Me" className={`duration-300 absolute top-0 z-10 w-full ${hover === "aboutme" ? "hover" : hover !== "" ? "opacity-40 blur-[2px] grayscale" : ""}`} />
      <img src={Assets.Specialization} alt="Specialization" className={`duration-300 absolute top-0 w-full ${hover === "specialization" ? "hover" : hover !== "" ? "opacity-40 blur-[2px] grayscale" : ""}`} />
      <img src={Assets.OtherWorks} alt="Other Works" className={`duration-300 absolute top-0 w-full ${hover === "otherworks" ? "hover" : hover !== "" ? "opacity-40 blur-[2px] grayscale" : ""}`} />
      <img src="/assets/main/effect.gif" alt="effect" className={`absolute top-0 w-full pointer-events-none ${mute ? "opacity-0" : "opacity-100"}`} />
      <map name="main-image" className="">
        <area 
          shape="poly" 
          alt="about-me" 
          id="about-me-area-map"
          title="about-me"
          className={`cursor-pointer`}
          onMouseEnter={() => setHover("aboutme")}
          onMouseLeave={() => setHover("")}
          onClick={() => openAbout(true)}
          coords="689,289,703,335,698,344,677,289,655,286,657,274,676,199,720,193,735,210,818,199,827,216,881,199,908,206,940,205,976,196,1012,179,1112,188,1118,213,1173,196,1208,213,1215,223,1215,235,1230,208,1241,220,1281,232,1290,254,1285,274,1278,298,1281,315,1280,328,1266,342,1232,320,1208,335,1198,290,1166,312,1078,290,1023,291,944,308,915,278,867,303,833,290,779,327,749,323,730,271" 
          data-coords="689,289,703,335,698,344,677,289,655,286,657,274,676,199,720,193,735,210,818,199,827,216,881,199,908,206,940,205,976,196,1012,179,1112,188,1118,213,1173,196,1208,213,1215,223,1215,235,1230,208,1241,220,1281,232,1290,254,1285,274,1278,298,1281,315,1280,328,1266,342,1232,320,1208,335,1198,290,1166,312,1078,290,1023,291,944,308,915,278,867,303,833,290,779,327,749,323,730,271" 
        />

        <area
          shape="poly"
          alt="specialization"
          id="specialization-area-map"
          title="specialization"
          className="cursor-pointer"
          onMouseEnter={() => setHover("specialization")}
          onMouseLeave={() => setHover("")}
          onClick={() => enterBox("spec")}
          coords="666,549,660,471,681,379,718,367,777,376,800,399,823,388,827,354,911,349,939,357,1017,335,1125,332,1200,379,1344,399,1427,440,1444,466,1429,544,1241,557,1061,576,830,589,676,576"
          data-coords="666,549,660,471,681,379,718,367,777,376,800,399,823,388,827,354,911,349,939,357,1017,335,1125,332,1200,379,1344,399,1427,440,1444,466,1429,544,1241,557,1061,576,830,589,676,576"
        />
        <area
          shape="poly"
          alt="other-works"
          id="other-works-area-map"
          title="other-works"
          className="cursor-pointer"
          onMouseEnter={() => setHover("otherworks")}
          onMouseLeave={() => setHover("")}
          onClick={() => enterBox("othw")}
          coords="545,618,637,602,840,595,995,602,1095,578,1308,549,1342,566,1336,605,1252,617,1256,641,1263,668,1242,696,1183,715,1118,737,942,734,895,741,835,744,793,744,725,744,633,798,608,819,562,822,547,783,576,773,542,707"
          data-coords="545,618,637,602,840,595,995,602,1095,578,1308,549,1342,566,1336,605,1252,617,1256,641,1263,668,1242,696,1183,715,1118,737,942,734,895,741,835,744,793,744,725,744,633,798,608,819,562,822,547,783,576,773,542,707"
        />
        <area shape="poly" alt="music-box" title="toggle music" className="cursor-pointer" onClick={() => toggleMute(!mute)} coords="845,833,996,817,1017,830,1017,898,867,915,849,888" data-coords="845,833,996,817,1017,830,1017,898,867,915,849,888" />
      </map>
    </div>
  )
}
export default Navigation;