import { Dispatch, SetStateAction, useContext } from "react";
import { y2s5c1_files } from "../../assets/ow_cd_y2c1";
import { y2s5c2_files } from "../../assets/ow_cd_y2c2";
import { y2s5c3_files } from "../../assets/ow_cd_y2c3";
import { y2s5fanart_files } from "../../assets/ow_cd_y2sb";
import { skatedeck_files, storyPitch_files, traditionalPaintings_files } from "../../assets/ow_just4fun";
import { vivid_files } from "../../assets/ow_vivid";
import { playerContext } from "../Background";
import { Item } from "../Specialization";

interface Props {
  title: string;
  item: Item;
  setItem?: Dispatch<SetStateAction<Item | undefined>>;
  hover: Dispatch<SetStateAction<string>>;
  className?: string;
}

const File: React.FC<Props> = ({ item, className, hover, setItem }) => {

  const onHover = () => {
    hover("file");
    if (setItem) setItem(item);
  }
  const initPlayer = useContext(playerContext);

  const openMediaViewer = () => {    
  /*
    skatedeck
    tradPaints
    vivid
    storyPitch_final
    ow_cd_y2c3
    ow_cd_y2c2
    ow_cd_y2c1
    unlocked
    tyler
    rocky
    sheck
    pglang
  */
    if (!item.src) {
      const collection = item.id.split("-")[0];
      let imgs: Array<FileProps> = [];
      if (collection === "skatedeck") 
        imgs = skatedeck_files.imgs;
      else if (collection === "tradPaints")
        imgs = traditionalPaintings_files;
      else if (collection === "vivid")
        imgs = vivid_files;
      else if (collection === "storyPitch_final")
        imgs = [...storyPitch_files.pitch1, ...storyPitch_files.pitch2, ...storyPitch_files.pitch3];
      else if (collection === "ow_cd_y2c3")
        imgs = y2s5c3_files;
      else if (collection === "ow_cd_y2c2")
        imgs = y2s5c2_files;
      else if (collection === "ow_cd_y2c1")
        imgs = y2s5c1_files;
      else if (collection === "unlocked")
        imgs = y2s5fanart_files.uncloked;
      else if (collection === "tyler")
        imgs = y2s5fanart_files.tyler;
      else if (collection === "rocky")
        imgs = y2s5fanart_files.rocky;
      else if (collection === "sheck")
        imgs = y2s5fanart_files.sheck;
      else if (collection === "pglang")
        imgs = y2s5fanart_files.pglang;
        
      const index = imgs.findIndex(img => img.title === item.title);
      const newImgs = [...imgs.slice(index), ...imgs.slice(0,index)]
      console.log(newImgs, index);

      console.log("Collection:", collection);
      initPlayer(item.title, "img", "", newImgs)
    } else {
      initPlayer(item.title, "vid", item.src);
    }
   
    // !item.src ? initPlayer(item.title, "img", "", [{thumb: item.thumb, title: item.title}]): initPlayer(item.title, "vid", item.src);
  }


  // const bg = {backgroundImage: `url(${item.thumb})`};
  const topClass = `hover:ring-4 ring-teal-500 bg-contain bg-no-repeat bg-center flex flex-col ${className} items-end w-full dpb-5 duration-300 rounded-lg backdrop-blur-sm bg-zinc-700/50 h-full [21.75rem] cursor-pointer`;
  return (
    <div onClick={openMediaViewer} onMouseEnter={onHover} className={topClass}>
      <img src={item.thumb} alt="" className={`object-cover object-center w-full h-full rounded-lg ${item.src ? "aspect-video" : "aspect-square"} rounded-t-md`} />
      
      {/* File Structure */}
      
      {/* <div className="w-full p-2 ">
        <div className="mt-5 text-xl font-semibold text-center">{item.title}</div>
        <hr className="my-5 border-zinc-600" />
        <div className="flex mx-2">
          <div className="flex flex-col items-start justify-center w-full">
              <span className="font-bold text-white">
                File Size: 
              </span>
              <span className="font-medium text-zinc-500"> 
                {item.size}
              </span>
          </div>
          <div className="flex items-center ml-auto text-xs whitespace-nowrap text-zinc-400">
            {item.date}
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default File;