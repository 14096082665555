const characterheader = "/assets/otherworks/headers/characterdesign.jpg";
const skateheader = "/assets/otherworks/headers/skatedeckmockups.jpg";
const storyheader = "/assets/otherworks/headers/storypitches.jpg";
const paintingheader = "/assets/otherworks/headers/traditionalpaintngs.jpg";
const videoheader = "/assets/otherworks/headers/videoedits.jpg";
const vividheader = "/assets/otherworks/headers/vivid.jpg";

const char_header_1 = "/assets/otherworks/headers/chars/char1.jpg"
const char_header_2 = "/assets/otherworks/headers/chars/char2.jpg"
const char_header_3 = "/assets/otherworks/headers/chars/char3.jpg"
const char_header_fanart = "/assets/otherworks/headers/chars/fanart.jpg"


export {
  characterheader,
  skateheader,
  storyheader,
  paintingheader,
  videoheader,
  vividheader,
  char_header_1,
  char_header_2,
  char_header_3,
  char_header_fanart
};