import { Dispatch, SetStateAction, useState } from "react"
import type { FolderItem } from "../Winbox";

import Sidebar from "../Parts/Sidebar";
import Titlebar from "../Parts/Titlebar"

import { skatedeck_files, storyPitch_files, traditionalPaintings_files } from "../../assets/ow_just4fun";
import File from "../Parts/File";
import { Item } from "../Specialization";
import * as owHeaders from "../../assets/ow_headers";

import "./index.css";
import { vivid_files } from "../../assets/ow_vivid";
import { video_files } from "../../assets/ow_videos";
import { motionFile, vansFile } from "../../assets/ow_motion_graphics";
import CharacterDesign from "./CharacterDesign";
import Breadcrumb from "../Parts/BreadCrumb";

interface Props {
  close: Dispatch<SetStateAction<boolean>>;
  setFull: () => void;
  full: boolean;
}

const OtherWorks: React.FC<Props> = ({ close, setFull, full }) => {
  const [title, setTitle] = useState<string>("Other Works");
  const [view, setView] = useState<string>("home");
  const [hover, changeHover] = useState<string>("");
  const [folderItem, setFolderItem] = useState<FolderItem>();
  const [item, setItem] = useState<Item>();

  // Navigation
  const [tree, updateTree] = useState([{name: "Other Works", path: "home"}]);
  const changeDir = (name: string, path: string, set: boolean = true) => {
    if (path === "home") updateTree([{name: "Other Works", path: "home"}]);
    updateTree([...tree, {name, path}]);
    setTitle(name);
    changeHover("");
    setItem(undefined);
    set ? setView(path) : console.log("not set");
  }
  const back = (item: { name: string; path: string }, set: boolean = true) => {
    const newTree = [...tree];
    newTree.pop();
    updateTree(newTree);
    setTitle(item.name);
    set ? setView(newTree[newTree.length - 1].path) : console.log("not set");
  }

  const goHome = () => {
    updateTree([{name: "Other Works", path: "home"}]);
    setTitle("Other Works");
    setView("home");
    changeHover("");
    setItem(undefined);
  }


  return (
    <div className="flex w-full h-full">
      <Titlebar close={close} setFull={setFull} view={view}  />
      <div className={`px-4 ${view === "home" ? "w-full" : "w-full xl:w-[75%]"}`}>
        <div className={`flex xl:mt-5 mx-5 ${view !== "home" ? "" : ""}`}>
          {view !== "home" && <svg onClick={() => goHome()} className="mr-3 duration-200 scale-90 cursor-pointer hover:-translate-x-1" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2rem" height="2rem" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3c0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8c24.9-25 24.9-65.5-.1-90.5z"/></svg>}
          <div className="w-full text-2xl font-bold tracking-widest">{title}</div>
        </div>
        <hr className="my-6 mr-10 border-slate-500" />
        <Breadcrumb tree={tree} back={back} className="mb-2 ml-4" />
        <div className={`flex w-full ${full ? "max-h-[38rem]" : "max-h-[32rem]"} oveflow-y-auto`}>
          <div className="flex flex-col w-full px-5 pt-2 overflow-y-auto">
            {view === "home" && 
              <>
                <img onClick={() => changeDir("Video Edits", "ve")} src={owHeaders.videoheader} alt="videoedits" className="hover:ring-4 ring-white img-headers" />
                <img onClick={() => changeDir("Character Designs", "cd")} src={owHeaders.characterheader} alt="characterdesigns" className="img-headers" />
                <img onClick={() => changeDir("Story Pitches", "sp")} src={owHeaders.storyheader} alt="storypitches" className="img-headers" />
                <img onClick={() => changeDir("Vivid", "vi")} src={owHeaders.vividheader} alt="vivid" className="img-headers" />
                <img onClick={() => changeDir("Traditional Paintings", "tp")} src={owHeaders.paintingheader} alt="tradpaintings" className="img-headers" />
                <img onClick={() => changeDir("Skate Deck Designs", "sd")} src={owHeaders.skateheader} alt="skatedeckdesigns" className="img-headers" />
              </>
            }
            {(view === "cd" || view === "Year_2_Sembreak_Fanart" || view === "Year_2_Sem5_Char1" || view === "Year_2_Sem5_Char2" || view === "Year_2_Sem5_Char3")  && <CharacterDesign changeDir={changeDir} back={back} setItem={setItem} hover={changeHover} setFolderItem={setFolderItem} view={view} setView={setView} />}
            {view === "sd" && <SkateDeckMockups hover={changeHover} setFolderItem={setFolderItem} setItem={setItem} />}
            {view === "tp" && <TraditionalPaintings hover={changeHover} setFolderItem={setFolderItem} setItem={setItem} />}
            {view === "sp" && <StoryPitches hover={changeHover} setFolderItem={setFolderItem} setItem={setItem} />}
            {view === "ve" && <VideoEdits hover={changeHover} setFolderItem={setFolderItem} setItem={setItem} />}
            {view === "vi" && <Vivid hover={changeHover} setFolderItem={setFolderItem} setItem={setItem} />}
            <div className="my-12" />
          </div>
        </div>
      </div>
      {view !== "home" && <div className="hidden xl:flex flex-col w-[25%] bg-gray-800 pt-5 lg:min-h-screen">
          <Sidebar hover={hover} changeHover={changeHover} item={hover === "folder" ? undefined : item} folderItem={hover === "folder" ? folderItem : undefined} />
      </div>} 
    </div>
    // <div className="flex flex-col w-full">
    //   <div className="flex justify-end w-full">
    //   <div className={`flex flex-col pr-2 pl-9 h-[50rem] dh-[calc(100%+10rem)] !overflow-y-auto ${view === "home" ? "w-full" : "w-full xl:w-[75%]"}`}>
    //     <div className={`flex xl:mt-10 ${view !== "home" ? "" : ""}`}>
    //       {view !== "home" && <svg onClick={() => setView("home")} className="mr-3 duration-200 scale-90 cursor-pointer hover:-translate-x-1" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2rem" height="2rem" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3c0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8c24.9-25 24.9-65.5-.1-90.5z"/></svg>}
    //       <div className="w-full text-2xl font-bold tracking-widest">{title}</div>
    //     </div>
    //     <hr className="my-6 mr-10 border-slate-500" />
    //     <Breadcrumb tree={tree} back={back} className="mb-2" />
    //     <div className="flex max-h-[34rem] flex-col px-5 pt-2 overflow-y-auto">
    //       {view === "home" && 
    //         <>
    //           <img onClick={() => changeDir("Video Edits", "ve")} src={owHeaders.videoheader} alt="videoedits" className="img-headers" />
    //           <img onClick={() => changeDir("Character Designs", "cd")} src={owHeaders.characterheader} alt="characterdesigns" className="img-headers" />
    //           <img onClick={() => changeDir("Story Pitches", "sp")} src={owHeaders.storyheader} alt="storypitches" className="img-headers" />
    //           <img onClick={() => changeDir("Vivid", "vi")} src={owHeaders.vividheader} alt="vivid" className="img-headers" />
    //           <img onClick={() => changeDir("Traditional Paintings", "tp")} src={owHeaders.paintingheader} alt="tradpaintings" className="img-headers" />
    //           <img onClick={() => changeDir("Skate Deck Designs", "sd")} src={owHeaders.skateheader} alt="skatedeckdesigns" className="img-headers" />
    //         </>
    //       }
    //       {(view === "cd" || view === "Year_2_Sembreak_Fanart" || view === "Year_2_Sem5_Char1" || view === "Year_2_Sem5_Char2" || view === "Year_2_Sem5_Char3")  && <CharacterDesign changeDir={changeDir} back={back} setItem={setItem} hover={changeHover} setFolderItem={setFolderItem} view={view} setView={setView} />}
    //       {view === "sd" && <SkateDeckMockups hover={changeHover} setFolderItem={setFolderItem} setItem={setItem} />}
    //       {view === "tp" && <TraditionalPaintings hover={changeHover} setFolderItem={setFolderItem} setItem={setItem} />}
    //       {view === "sp" && <StoryPitches hover={changeHover} setFolderItem={setFolderItem} setItem={setItem} />}
    //       {view === "ve" && <VideoEdits hover={changeHover} setFolderItem={setFolderItem} setItem={setItem} />}
    //       {view === "vi" && <Vivid hover={changeHover} setFolderItem={setFolderItem} setItem={setItem} />}
    //     </div>
    //     </div>
    //     {view !== "home" && <div className="hidden xl:flex flex-col w-[25%] bg-gray-800 pt-5 lg:min-h-screen">
    //       <Sidebar hover={hover} changeHover={changeHover} item={hover === "folder" ? undefined : item} folderItem={hover === "folder" ? folderItem : undefined} />
    //     </div>} 
    //   </div>
    // </div>
  )
}

interface FolderProps {
  setFolderItem: Dispatch<SetStateAction<FolderItem | undefined>>;
  hover: Dispatch<SetStateAction<string>>;
  setItem?: Dispatch<SetStateAction<Item | undefined>>;
}

const SkateDeckMockups: React.FC<FolderProps> = ({ hover, setItem }) => {

  return (
    <div className="grid grid-cols-3 gap-5">
      <div className="col-span-3 font-mono font-bold tracking-wider text-gray-500 uppercase divider">Videos</div>
      {skatedeck_files.vids.map((vid, index) => <File hover={hover} setItem={setItem} title={vid.title} item={vid} key={index} />)}
      <div className="col-span-3 font-mono font-bold tracking-wider text-gray-500 uppercase divider">Images</div>
      {skatedeck_files.imgs.map((img, index) => <File hover={hover} setItem={setItem} title={img.title} item={img} key={index} />)}
    </div>
  )
}

const StoryPitches: React.FC<FolderProps> = ({ hover, setItem }) => {
  return (
    <div className="grid grid-cols-3 gap-5">
      <div className="col-span-3 font-mono font-bold tracking-wider text-gray-500 uppercase divider">Story Pitch #1</div>
      {storyPitch_files.pitch1.map(pitch => <File hover={hover} setItem={setItem} title={pitch.title} item={pitch} />)}

      <div className="col-span-3 font-mono font-bold tracking-wider text-gray-500 uppercase divider">Story Pitch #2</div>
      {storyPitch_files.pitch2.map(pitch => <File hover={hover} setItem={setItem} title={pitch.title} item={pitch} />)}
      
      <div className="col-span-3 font-mono font-bold tracking-wider text-gray-500 uppercase divider">Story Pitch #3</div>
      {storyPitch_files.pitch2.map(pitch => <File hover={hover} setItem={setItem} title={pitch.title} item={pitch} />)}
    </div>
  )
}

const TraditionalPaintings: React.FC<FolderProps> = ({ setItem, hover }) => {

  return (
    <div className="grid grid-cols-3 gap-5">
      <div className="col-span-3 font-mono font-bold tracking-wider text-gray-500 uppercase divider">Painting #1</div>      
      {traditionalPaintings_files.map((painting, index) => (index < 3 && <File hover={hover} setItem={setItem} title={painting.title} item={painting} key={index} />))}
      <div className="col-span-3 font-mono font-bold tracking-wider text-gray-500 uppercase divider">Painting #2</div>      
      {traditionalPaintings_files.map((painting, index) => ((index >= 3 && index < 6) && <File hover={hover} setItem={setItem}  title={painting.title}  item={painting} key={index} />))}
      <div className="col-span-3 font-mono font-bold tracking-wider text-gray-500 uppercase divider">Painting #3</div>
      {traditionalPaintings_files.map((painting, index) => (index >= 6 && <File hover={hover} setItem={setItem} title={painting.title} item={painting} key={index} />))}
    </div>
  )
}

const Vivid: React.FC<FolderProps> = ({ setItem, hover }) => {
  return (
    <div className="grid grid-cols-3 gap-5">
      {vivid_files.map((file, i) => 
        <File key={i} hover={hover} setItem={setItem} title={file.title} item={file} />
      )}
    </div>
  )
}


const VideoEdits: React.FC<FolderProps> = ({ setItem, hover }) => {
  return (
    <div className="grid grid-cols-3 gap-5">
      <div className="col-span-3 font-mono font-bold tracking-wider text-gray-500 uppercase divider">Just For Fun</div>
      {video_files.map((item, index) => 
        <File key={index} hover={hover} setItem={setItem} title={item.title} item={item} />
      )}
      <div className="col-span-3 font-mono font-bold tracking-wider text-gray-500 uppercase divider">Motion Graphics</div>
      <File title="Motion_Graphics" item={motionFile} hover={hover} setItem={setItem} />
      <File title="Vans_Commerical" item={vansFile} hover={hover} setItem={setItem} />
    </div>
  )
}

export default OtherWorks;