// pglang
const pglang_bottom_cover = "/assets/otherworks/character design/year2_sembreak_fanart/pglang/Jorja/cover.jpg";
const pglang_bottom_2 = "/assets/otherworks/character design/year2_sembreak_fanart/pglang/Jorja/2.jpg";
const pglang_bottom_3 = "/assets/otherworks/character design/year2_sembreak_fanart/pglang/Jorja/3.jpg";
const pglang_bottom_4 = "/assets/otherworks/character design/year2_sembreak_fanart/pglang/Jorja/4.jpg";
const pglang_bottom_5 = "/assets/otherworks/character design/year2_sembreak_fanart/pglang/Jorja/5.jpg";
const pglang_bottom_6 = "/assets/otherworks/character design/year2_sembreak_fanart/pglang/Jorja/6.jpg";

const pglang_top_cover = "/assets/otherworks/character design/year2_sembreak_fanart/pglang/top/cover.jpg";
const pglang_top_2 = "/assets/otherworks/character design/year2_sembreak_fanart/pglang/top/2.jpg";
const pglang_top_3 = "/assets/otherworks/character design/year2_sembreak_fanart/pglang/top/3.jpg";
const pglang_top_4 = "/assets/otherworks/character design/year2_sembreak_fanart/pglang/top/4.jpg";
const pglang_top_5 = "/assets/otherworks/character design/year2_sembreak_fanart/pglang/top/5.jpg";
const pglang_top_6 = "/assets/otherworks/character design/year2_sembreak_fanart/pglang/top/6.jpg";

const pglang_main = "/assets/otherworks/character design/year2_sembreak_fanart/pglang/MAIN.jpg";

// rocky
const rocky_cover = "/assets/otherworks/character design/year2_sembreak_fanart/rocky/cover.jpg";
const rocky_2 = "/assets/otherworks/character design/year2_sembreak_fanart/rocky/2.jpg";
const rocky_3 = "/assets/otherworks/character design/year2_sembreak_fanart/rocky/3.jpg";
const rocky_4 = "/assets/otherworks/character design/year2_sembreak_fanart/rocky/4.jpg";
const rocky_5 = "/assets/otherworks/character design/year2_sembreak_fanart/rocky/5.jpg";

// sheck
const sheck_cover = "/assets/otherworks/character design/year2_sembreak_fanart/sheck/cover.jpg";
const sheck_1 = "/assets/otherworks/character design/year2_sembreak_fanart/sheck/2.jpg";
const sheck_2 = "/assets/otherworks/character design/year2_sembreak_fanart/sheck/3.jpg";
const sheck_3 = "/assets/otherworks/character design/year2_sembreak_fanart/sheck/4.jpg";
const sheck_4 = "/assets/otherworks/character design/year2_sembreak_fanart/sheck/5.jpg";
const sheck_5 = "/assets/otherworks/character design/year2_sembreak_fanart/sheck/6.jpg";
const sheck_6 = "/assets/otherworks/character design/year2_sembreak_fanart/sheck/7.jpg";
const sheck_7 = "/assets/otherworks/character design/year2_sembreak_fanart/sheck/8.jpg";

// tyler the creator
const tyler_cover = "/assets/otherworks/character design/year2_sembreak_fanart/tyler the creator/cover.jpg";
const tyler_1 = "/assets/otherworks/character design/year2_sembreak_fanart/tyler the creator/2.jpg";
const tyler_2 = "/assets/otherworks/character design/year2_sembreak_fanart/tyler the creator/3.jpg";
const tyler_3 = "/assets/otherworks/character design/year2_sembreak_fanart/tyler the creator/4.jpg";
const tyler_4 = "/assets/otherworks/character design/year2_sembreak_fanart/tyler the creator/5.jpg";
const tyler_5 = "/assets/otherworks/character design/year2_sembreak_fanart/tyler the creator/6.jpg";
const tyler_6 = "/assets/otherworks/character design/year2_sembreak_fanart/tyler the creator/7.jpg";

// uncloked
const uncloked_cover = "/assets/otherworks/character design/year2_sembreak_fanart/uncloked/cover.jpg";
const uncloked_1 = "/assets/otherworks/character design/year2_sembreak_fanart/uncloked/2.jpg";
const uncloked_2 = "/assets/otherworks/character design/year2_sembreak_fanart/uncloked/3.jpg";
const uncloked_3 = "/assets/otherworks/character design/year2_sembreak_fanart/uncloked/4.jpg";




const pglang = {
  title: "Fanart: pgLang",
  images: [
    pglang_main,
    pglang_bottom_cover, pglang_bottom_2, pglang_bottom_3, pglang_bottom_4, pglang_bottom_5, pglang_bottom_6,
    pglang_top_cover, pglang_top_2, pglang_top_3, pglang_top_4, pglang_top_5, pglang_top_6,
  ],
  files: 13,
  description: `Placeholder Text for pgLang`
}

const rocky = {
  title: "Fanart: A$AP Rocky",
  images: [
    rocky_cover, rocky_2, rocky_3, rocky_4, rocky_5,
  ],
  files: 5,
  description: `Placeholder Text for rocky`
}

const sheck = {
  title: "Sheck Wes",
  images: [
    sheck_cover, sheck_1, sheck_2, sheck_3, sheck_4, sheck_5, sheck_6, sheck_7
  ],
  files: 8,
  description: `Placeholder Text for sheck`
}

const tyler_the_creator = {
  title: "Tyler the Creator",
  images: [
    tyler_cover, tyler_1, tyler_2, tyler_3, tyler_4, tyler_5, tyler_6
  ],
  files: 7,
  description: `Placeholder Text for tyler the creator`
}

const uncloked = {
  title: "Uncloked",
  images: [
    uncloked_cover, uncloked_1, uncloked_2, uncloked_3
  ],
  files: 4,
  description: `Placeholder Text for uncloked`
}

const y2s5fanart = {
  title: "2-5: Fanarts",
  images: [
    tyler_cover,
  ],
  files: 5,
  description: `Collection of Fanart Images`
}

// /**
//  * @type {
//  *  pglang: Array<FileProps>,
//  *  rocky: Array<FileProps>,
//  *  sheck: Array<FileProps>,
//  *  tyler: Array<FileProps>,
//  *  uncloked: Array<FileProps>,
//  * }
//  */
export const y2s5fanart_files = {
  pglang: [
    {
      id: "pglang-full",
      title: "pglang_full",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: pglang_main,
    },
    {
      id: "pglang-top",
      title: "pglang_top",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: pglang_top_cover,
    },
    {
      id: "pglang-t1",
      title: "pglang_top_1",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: pglang_top_2,
    },
    {
      id: "pglang-t2",
      title: "pglang_top_2",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: pglang_top_2,
    },
    {
      id: "pglang-t3",
      title: "pglang_top_3",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: pglang_top_3,
    },
    {
      id: "pglang-t4",
      title: "pglang_top_4",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: pglang_top_4,
    },
    {
      id: "pglang-t5",
      title: "pglang_top_5",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: pglang_top_5,
    },
    {
      id: "pglang-t6",
      title: "pglang_top_6",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: pglang_top_6,
    },
    {
      id: "pglang-bottom",
      title: "pglang_bottom",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: pglang_bottom_cover,
    },
    {
      id: "pglang-b1",
      title: "pglang_bottom_1",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: pglang_bottom_2,
    },
    {
      id: "pglang-b1",
      title: "pglang_bottom_2",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: pglang_bottom_3,
    },
    {
      id: "pglang-b1",
      title: "pglang_bottom_3",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: pglang_bottom_4,
    },
    {
      id: "pglang-b1",
      title: "pglang_bottom_4",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: pglang_bottom_5,
    },
    {
      id: "pglang-b1",
      title: "pglang_bottom_5",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: pglang_bottom_6,
    }
  ],
  rocky: [
    {
      id: "rocky-cover",
      title: "rocky_cover",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: rocky_cover,
    },
    {
      id: "rocky-1",
      title: "rocky_1",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: rocky_2,
    },
    {
      id: "rocky-2",
      title: "rocky_2",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: rocky_3,
    },
    {
      id: "rocky-3",
      title: "rocky_3",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: rocky_4,
    },
    {
      id: "rocky-4",
      title: "rocky_4",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: rocky_5,
    }
  ],
  sheck: [
    {
      id: "sheck-cover",
      title: "sheck_cover",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: sheck_cover,
    },
    {
      id: "sheck-1",
      title: "sheck_1",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: sheck_1,
    },
    {
      id: "sheck-2",
      title: "sheck_2",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: sheck_2,
    },
    {
      id: "sheck-3",
      title: "sheck_3",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: sheck_3,
    },
    {
      id: "sheck-4",
      title: "sheck_4",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: sheck_4,
    },
    {
      id: "sheck-5",
      title: "sheck_5",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: sheck_5,
    },
    {
      id: "sheck-6",
      title: "sheck_6",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: sheck_6,
    },
    {
      id: "sheck-7",
      title: "sheck_7",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: sheck_7,
    }
  ],
  tyler: [
    {
      id: "tyler-cover",
      title: "tyler_cover",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: tyler_cover,
    },
    {
      id: "tyler-1",
      title: "tyler_1",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: tyler_1,
    },
    {
      id: "tyler-2",
      title: "tyler_2",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: tyler_2,
    },
    {
      id: "tyler-2",
      title: "tyler_2",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: tyler_2,
    },
    {
      id: "tyler-3",
      title: "tyler_3",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: tyler_3,
    },
    {
      id: "tyler-4",
      title: "tyler_4",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: tyler_4,
    },
    {
      id: "tyler-5",
      title: "tyler_5",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: tyler_5,
    },
    {
      id: "tyler-6",
      title: "tyler_6",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: tyler_6,
    },
  ],
  uncloked: [
    {
      id: "unlocked-cover",
      title: "unlocked_cover",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: uncloked_cover,
    },
    {
      id: "unlocked-1",
      title: "unlocked_1",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: uncloked_1,
    },
    {
      id: "unlocked-2",
      title: "unlocked_2",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: uncloked_2,
    },
    {
      id: "unlocked-3",
      title: "unlocked_3",
      date: "2019-01-01",
      description: y2s5fanart.description,
      size: "1.1 MB",
      src: null,
      thumb: uncloked_3,
    },
  ]
}


export default y2s5fanart;

export {
  pglang,
  rocky,
  sheck,
  tyler_the_creator,
  uncloked
}